import { SurveyAnswerInstance } from "app/surveys/modules/state/model/Model"
import { ViewComponentProps } from "core/components/base/ViewComponent"
import * as csx from "csx"
import Grid from "lib/ui/components/layout/grid/Grid"
import { CategoryPrefix } from "lib/utils/report/WellbeingCommonReportUtils"
import { stylesheet } from "typestyle"
import { ViewComponent } from "../../../../../base/ViewComponent"
import { ReportVariant } from "../../WellbeingReport"
import ElementDetailsContainer from "./ElementDetailsContainer"

interface Props extends ViewComponentProps {
  answer: SurveyAnswerInstance
  comparison: SurveyAnswerInstance | undefined
  reportVariant: ReportVariant
}

export default class ReportSectionElementDetails extends ViewComponent<Props> {
  get componentName() {
    return ["report", "pages", "report", "ReportSectionElementDetails"]
  }

  constructor(props) {
    super(props)

    setupStyles(props.reportVariant)
  }

  render() {
    return (
      <>
        <Grid container className={styles.container}>
          <Grid sm={12}>{this.renderCategories()}</Grid>
        </Grid>
      </>
    )
  }

  private renderCategories() {
    const { reportVariant } = this.props

    const categories = [
      CategoryPrefix.CORE,
      CategoryPrefix.PHYSICAL_ACTIVITY,
      CategoryPrefix.NUTRITION,
      CategoryPrefix.GENERAL_HEALTH,
      CategoryPrefix.MENTAL_ENERGY,
      CategoryPrefix.BIOMECHANICS,
      CategoryPrefix.SLEEP_AND_RECOVERY
    ]

    return categories.map(categoryPrefix => (
      <ElementDetailsContainer
        key={categoryPrefix}
        categoryPrefix={categoryPrefix}
        answer={this.props.answer}
        comparison={this.props.comparison}
        reportVariant={reportVariant}
      />
    ))
  }
}

let styles

const setupStyles = (reportVariant: ReportVariant) => {
  const ivory = ElementDetailsContainer.theme.colors.hintsa.ivory

  styles = stylesheet({
    container: {
      width: csx.important("unset"),
      marginBottom: "2rem",
      borderRadius: "0.5rem",
      marginLeft: reportVariant === "mobile" ? "-1rem" : undefined,
      marginRight: reportVariant === "mobile" ? "-1rem" : undefined,
      boxShadow:
        reportVariant === "pdf"
          ? undefined
          : `inset 0 0 2rem rgba(${ivory.red()}, ${ivory.green()}, ${ivory.blue()}, 0.15)`
    }
  })
}
