import { SurveyAnswerInstance } from "app/surveys/modules/state/model/Model"
import classNames from "classnames"
import { ViewComponentProps } from "core/components/base/ViewComponent"
import Grid from "lib/ui/components/layout/grid/Grid"
import Typography from "lib/ui/components/typography/Typography"
import {
  getAnswerMaxPoints,
  getAnswerMinPoints,
  getAnswerPoints,
  getAnswerTitle,
  getScoreColorSetpointsForAnswer
} from "lib/utils/report/WellbeingPersonalReportUtils"
import React from "react"
import { stylesheet } from "typestyle"
import { ViewComponent } from "../../../../../base/ViewComponent"
import { ReportVariant, reportSmallBodyClass, reportSmallHeadingClass } from "../../WellbeingReport"
import ScoreMeter from "../../ui/ScoreMeter"

interface Props extends ViewComponentProps {
  answer: SurveyAnswerInstance
  reportVariant: ReportVariant
}

export default class ReportSectionCoachInfo extends ViewComponent<Props> {
  get componentName() {
    return ["report", "pages", "report", "ReportSectionCoachInfo"]
  }

  constructor(props) {
    super(props)

    setupStyles(props.reportVariant)
  }

  render() {
    const { reportVariant } = this.props

    return (
      <>
        {reportVariant === "pdf" && <div className="hard-page-break" />}
        <Grid container className={styles.container}>
          <Grid xs={12}>
            <Typography useContrastColor className={classNames(reportSmallHeadingClass, styles.heading1)}>
              {this.txt("heading")}
            </Typography>
            <div className={styles.centered}></div>
          </Grid>
          <Grid spacer spacerSize={2} />
          {this.renderStandardAnswersNotInUserReport()}
          {this.renderOpenAnswersNotInReport()}
          {this.renderChangeDesire()}
        </Grid>
      </>
    )
  }

  private renderOpenAnswersNotInReport() {
    const answers = this.props.answer.answers!.filter(answer =>
      ["gm_goal", "wc_goal_exceed", "wc_changes"].includes(answer.question_key!)
    )

    return answers
      .map(
        answer =>
          answer?.options?.[0] && (
            <>
              <Grid xs={12} className={styles.hrGrid}>
                <hr className={styles.hr} />
              </Grid>
              <Grid xs={12} sm={9} className={styles.answerTitleContainer}>
                <Typography useContrastColor className={reportSmallBodyClass}>
                  {this.txt(answer.title)}
                </Typography>
              </Grid>
              <Grid xs={12} sm={3} className={styles.answerTitleContainer}>
                <Typography useContrastColor className={reportSmallBodyClass}>
                  {answer.options?.[0]?.note}
                </Typography>
              </Grid>
            </>
          )
      )
      .filter(Boolean)
  }

  private renderStandardAnswersNotInUserReport() {
    const answers = this.props.answer.answers!.filter(answer =>
      [
        "gm_change_need",
        "gm_change_motivation",
        "gm_change_capability",
        "gm_change_support",
        "wc_goal",
        "wc_lifestyle_changes"
      ].includes(answer.question_key!)
    )
    return answers
      .map(
        answer =>
          answer?.options?.[0] && (
            <React.Fragment key={answer.question_id}>
              <Grid sm={12} className={styles.hrGrid}>
                <hr className={styles.hr} />
              </Grid>
              <Grid xs={12} sm={9} className={styles.answerTitleContainer}>
                <Typography useContrastColor className={reportSmallBodyClass}>
                  {this.txt(answer.title)}
                </Typography>
              </Grid>
              <Grid xs={12} sm={3} className={styles.scoreContainer}>
                <ScoreMeter
                  className={styles.scoreMeter}
                  showScoreIndex
                  score={getAnswerPoints(answer)}
                  scoreDescription={getAnswerTitle(answer, this.localization)}
                  minScore={getAnswerMinPoints(answer)}
                  maxScore={getAnswerMaxPoints(answer)}
                  scoreColorCalculationMethod="value"
                  scoreColorSetpoints={getScoreColorSetpointsForAnswer(answer)}
                />
              </Grid>
            </React.Fragment>
          )
      )
      .filter(Boolean)
  }

  private renderChangeDesire() {
    const { answer } = this.props

    const changeDesire = answer.answers!.find(a => a.question_key === "gm_change_desire")

    if (!changeDesire) return null

    const answers = changeDesire.options?.map(option => this.txt(option.title)).join(", ")

    return (
      <>
        <Grid xs={12} className={styles.hrGrid}>
          <hr className={styles.hr} />
        </Grid>
        <Grid xs={12} sm={9} className={styles.answerTitleContainer}>
          <Typography useContrastColor className={reportSmallBodyClass}>
            {this.txt(changeDesire.title)}
          </Typography>
        </Grid>
        <Grid xs={12} sm={3} className={styles.answerTitleContainer}>
          <Typography useContrastColor className={reportSmallBodyClass}>
            {answers}
          </Typography>
        </Grid>
      </>
    )
  }
}

let styles

const setupStyles = (reportVariant: ReportVariant) => {
  styles = stylesheet({
    headingTop: {
      marginTop: "0rem",
      marginBottom: "0.5rem",
      fontWeight: "bold"
    },
    container: {
      padding: reportVariant === "mobile" ? "1rem 2rem" : "2rem 3rem",
      marginBottom: "2rem",
      border: "double 3px transparent",
      borderRadius: "1rem",
      // Hack to combine border radius with linear-gradient. The biggest apparent downside
      // is the need to have a solid background. Please don't spend too much time
      // looking for a better solution unless there's an actual issue.
      background: `linear-gradient( ${ReportSectionCoachInfo.theme.colors.hintsa.charcoal.toString()}, ${ReportSectionCoachInfo.theme.colors.hintsa.charcoal.toString()}) padding-box, linear-gradient(92.7deg, #EA9F88 0%, #FFE0D4 20%, #F8F6E3 40%, #B8CBC6 60%, #7996A1 80%, #537D81 100%) border-box;`
    },
    scoreContainer: {
      display: "flex",
      marginBottom: "-0.5rem",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      height: "100%"
    }
  })
}
