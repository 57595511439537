import classNames from "classnames"
import { ViewComponentProps } from "core/components/base/ViewComponent"
import { TranslatedString } from "core/modules/state/model/Model"
import * as csx from "csx"
import Typography from "lib/ui/components/typography/Typography"
import { getValueColor, getValueDescription } from "lib/utils/report/WellbeingCommonReportUtils"
import { stylesheet } from "typestyle"
import { ViewComponent } from "../../../../../base/ViewComponent"
import { ReportVariant, reportLargeBodyClass, reportSmallBodyClass, reportSubHeadingClass } from "../../WellbeingReport"

interface Props extends ViewComponentProps {
  categoryName: TranslatedString
  categoryDescription: TranslatedString
  iconUrl: string
  points: number
  maxPoints: number
  variant: ReportVariant
}

export default class ReportCoblCategory extends ViewComponent<Props> {
  get componentName() {
    return ["report", "pages", "report", "ReportCoblCategory"]
  }

  constructor(props) {
    super(props)

    setupStyles(props.variant)
  }

  render() {
    return (
      <div>
        {this.renderNameArea()}
        <hr className={styles.divider} />
        {this.renderScoreArea()}
        {this.renderDescription()}
      </div>
    )
  }

  private renderNameArea() {
    const { categoryName, iconUrl } = this.props

    return (
      <div className={styles.nameArea}>
        <img alt="" className={styles.icon} src={iconUrl} />
        <Typography useContrastColor className={reportSubHeadingClass}>
          {this.txt(categoryName)}
        </Typography>
      </div>
    )
  }

  private renderScoreArea() {
    const { points, maxPoints } = this.props

    return (
      <div className={styles.scoreArea}>
        <div className={styles.scoreValue}>
          <Typography useContrastColor className={classNames(reportLargeBodyClass, styles.score)}>
            {points?.toFixed(1)}
          </Typography>
          <Typography useContrastColor className={classNames(reportSmallBodyClass, styles.scoreMax)}>
            {`/${maxPoints?.toFixed(1)}`}
          </Typography>
        </div>
        <div
          style={{ backgroundColor: getValueColor(points, this.theme).toString() }}
          className={classNames(styles.scoreEvaluation)}
        >
          <Typography>{getValueDescription(points, this.localization, this.appName)}</Typography>
        </div>
      </div>
    )
  }
  private renderDescription() {
    const { categoryDescription } = this.props

    return (
      <Typography useContrastColor className={styles.description}>
        {this.txt(categoryDescription)}
      </Typography>
    )
  }
}

let styles

const setupStyles = (variant: ReportVariant) => {
  styles = stylesheet({
    nameArea: {
      display: "flex",
      alignItems: "center",
      marginBottom: "-0.5rem"
    },
    icon: {
      width: "3rem",
      height: "3rem",
      marginRight: "1rem"
    },
    divider: {
      marginRight: "1.5rem",
      borderColor: ReportCoblCategory.theme.colors.hintsa.charcoal.toString()
    },
    scoreArea: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingRight: variant === "mobile" ? "0rem" : "1.5rem",
      marginTop: "1rem",
      marginBottom: "1rem"
    },
    scoreValue: {
      display: "flex",
      alignItems: "baseline"
    },
    score: {
      display: "flex",
      margin: 0
    },
    scoreMax: {
      margin: 0,
      color: csx.important(ReportCoblCategory.theme.colors.hintsa.alabaster.toString())
    },
    scoreEvaluation: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "0.25rem",
      minWidth: "10rem",
      height: "2rem"
    },
    description: {
      marginTop: "2rem",
      paddingRight: "1rem"
    }
  })
}
