import { ViewComponentProps } from "core/components/base/ViewComponent"
import RadioField, { RadioOption } from "lib/ui/components/form/RadioField"

import { ViewComponent } from "../../base/ViewComponent"

interface RadioQuestionViewProps extends ViewComponentProps {
  disabled?: boolean
  options: RadioOption[]
  className?: string
  value: string
  onChange: (value: string) => void
}

class RadioQuestionView extends ViewComponent<RadioQuestionViewProps> {
  get componentName() {
    return ["survey", "ui", "questions", "RadioQuestionView"]
  }

  render() {
    super.render()

    const { disabled, onChange, options, value, className } = this.props

    return (
      <RadioField
        noGroupMargin={true}
        disabled={disabled}
        value={value}
        variant="bold"
        spacing="tight"
        options={options}
        labelPlacement="end"
        className={className}
        onChange={onChange}
      />
    )
  }
}

export default RadioQuestionView
