import classNames from "classnames"
import QRCode from "react-qr-code"
import { stylesheet } from "typestyle"

import { ViewComponentProps } from "core/components/base/ViewComponent"
import Grid from "lib/ui/components/layout/grid/Grid"
import Markdown from "lib/ui/components/markdown/Markdown"
import Typography from "lib/ui/components/typography/Typography"
import { ViewComponent } from "../../../../../base/ViewComponent"
import {
  ReportVariant,
  reportBodyClass,
  reportLargeBodyClass,
  reportMediumHeadingClass,
  reportSmallHeadingClass
} from "../../WellbeingReport"

interface Props extends ViewComponentProps {
  reportVariant: ReportVariant
}

type VimeoVideoInfo = {
  // Vimeo video identifier
  vimeoId: string
  // Some other identifier needed for the embedding to work
  hValue: string
  // Title of the video
  titleTranslationKey: string
  // Backup picture to show in pdf
  backupPicture?: string
  // Link to show in PDF
  videoLink: string
  // Video description translation key
  descriptionTranslationKey?: string
}

const whereToStartVideo: VimeoVideoInfo = {
  vimeoId: "1010874103",
  hValue: "59fd73c4ee",
  titleTranslationKey: "",
  backupPicture: "where_to_start.webp",
  videoLink: "https://vimeo.com/1010874103/59fd73c4ee?share=copy"
}

const coachCornerVideos: VimeoVideoInfo[] = [
  {
    vimeoId: "954359732",
    hValue: "16926bb3a3",
    titleTranslationKey: "coach_message_1",
    backupPicture: "coach_01.webp",
    videoLink: "https://vimeo.com/954359732/16926bb3a3?share=copy",
    descriptionTranslationKey: "coach_message_1"
  },

  {
    vimeoId: "954356265",
    hValue: "3900480ba3",
    titleTranslationKey: "coach_message_2",
    backupPicture: "coach_02.webp",
    videoLink: "https://vimeo.com/954356265/3900480ba3?share=copy",
    descriptionTranslationKey: "coach_message_2"
  },
  {
    vimeoId: "954356472",
    hValue: "12e6758241",
    titleTranslationKey: "coach_message_3",
    backupPicture: "coach_03.webp",
    videoLink: "https://vimeo.com/954356472/12e6758241?share=copy",
    descriptionTranslationKey: "coach_message_3"
  },
  {
    vimeoId: "954356411",
    hValue: "614debcdd1",
    titleTranslationKey: "coach_message_4",
    backupPicture: "coach_04.webp",
    videoLink: "https://vimeo.com/954356411/614debcdd1?share=copy",
    descriptionTranslationKey: "coach_message_4"
  }
]

export default class ReportSectionContinueJourney extends ViewComponent<Props> {
  get componentName() {
    return ["report", "pages", "report", "ReportSectionContinueJourney"]
  }

  constructor(props) {
    super(props)

    setupStyles(props.reportVariant)
  }

  render() {
    const { reportVariant } = this.props

    return (
      <>
        {reportVariant === "pdf" && <div className="hard-page-break" />}
        <div className={styles.containerOuter}>
          <Grid container className={classNames(styles.containerInner)}>
            {this.renderContinueJourney()}
            {this.renderCoachCorner()}
          </Grid>
        </div>
      </>
    )
  }

  private renderContinueJourney() {
    return (
      <>
        <Grid xs={12} sm={7}>
          <Typography useContrastColor className={reportMediumHeadingClass}>
            {this.txt("continue_journey_title")}
          </Typography>
          <Typography useContrastColor className={classNames(reportLargeBodyClass, styles.achieveGoal)}>
            {this.txt("achieve_goal")}
          </Typography>
          <Typography useContrastColor className={classNames(reportLargeBodyClass, styles.learnWoop)}>
            {this.txt("learn_woop")}
          </Typography>
          <Typography useContrastColor component="div" className={reportBodyClass}>
            <Markdown content={this.txt("woop_content")} />
          </Typography>
        </Grid>
        <Grid xs={12} sm={5} className={styles.woopVideoGrid}>
          <div className={styles.woopVideoOuter}>{this.renderVideo(whereToStartVideo, true)}</div>
          {this.renderQRCode(whereToStartVideo)}
        </Grid>
      </>
    )
  }

  private renderCoachCorner() {
    const { reportVariant } = this.props

    return (
      <>
        <Grid spacer spacerSize={reportVariant === "desktop" ? 8 : 4} />
        <Grid sm={12}>
          <Typography useContrastColor className={classNames(reportSmallHeadingClass, styles.coachCorner)}>
            {this.txt("coach_corner")}
          </Typography>
        </Grid>
        <Grid sm={12}>
          <Typography useContrastColor className={classNames(reportBodyClass, styles.coachCornerDetails)}>
            {this.txt("coach_corner_details")}
          </Typography>
        </Grid>
        {this.renderCoaches()}
        <Grid spacer spacerSize={reportVariant === "desktop" ? 6 : 1} />
      </>
    )
  }

  private renderCoaches() {
    return coachCornerVideos.map(videoInfo => this.renderCoachVideo(videoInfo))
  }

  private renderCoachVideo(videoInfo: VimeoVideoInfo) {
    const { reportVariant } = this.props

    return (
      <Grid xs={12} sm={3} key={videoInfo.videoLink}>
        <div className={styles.videoOuterContainer}>
          {this.renderVideo(videoInfo)}
          {reportVariant === "pdf" ? (
            <a href={videoInfo.videoLink} className={styles.link}>
              <Typography useContrastColor className={classNames(reportBodyClass, styles.coachCornerDescription)}>
                {this.txt(videoInfo.descriptionTranslationKey)}
              </Typography>
            </a>
          ) : (
            <Typography useContrastColor className={classNames(reportBodyClass, styles.coachCornerDescription)}>
              {this.txt(videoInfo.descriptionTranslationKey)}
            </Typography>
          )}
          {this.renderQRCode(videoInfo)}
        </div>
      </Grid>
    )
  }

  private renderVideo(videoInfo: VimeoVideoInfo, large?: boolean) {
    const { reportVariant } = this.props

    if (reportVariant === "pdf") {
      return (
        <div
          className={styles.backupPicture}
          style={{
            backgroundColor: "red",
            backgroundImage: `url(${this.appConfig.imagesRoot}/wellbeing_report/${videoInfo.backupPicture})`
          }}
        ></div>
      )
    }

    // Todo: get correct video id and other properties
    return (
      <>
        <div className={large ? styles.videoInnerContainerLarge : styles.videoInnerContainer}>
          <iframe
            src={`https://player.vimeo.com/video/${videoInfo.vimeoId}?h=${videoInfo.hValue}&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
            style={{
              position: reportVariant === "mobile" ? undefined : "absolute",
              top: 0,
              left: 0,
              width: reportVariant === "mobile" ? "calc(100vw - 4rem)" : "100%",
              height: reportVariant === "mobile" ? "calc((100vw - 4rem) * 1.8)" : "100%"
            }}
            title={this.txt(videoInfo.titleTranslationKey)}
          ></iframe>
        </div>
      </>
    )
  }

  private renderQRCode(videoInfo: VimeoVideoInfo) {
    const { reportVariant } = this.props

    if (reportVariant !== "pdf") return null

    return (
      <div className={styles.qrContainer}>
        <QRCode value={videoInfo.videoLink} />
      </div>
    )
  }
}

let styles

const setupStyles = (reportVariant: ReportVariant) => {
  const ivoryColor = ReportSectionContinueJourney.theme.colors.hintsa.ivory

  styles = stylesheet({
    containerInner: {
      padding: reportVariant == "desktop" ? "2rem 4rem" : "2rem 1.5rem",
      borderRadius: "1rem",
      boxShadow:
        reportVariant === "pdf"
          ? undefined
          : `inset 0 15rem 4rem -14rem rgba(${ivoryColor.red()}, ${ivoryColor.green()}, ${ivoryColor.blue()}, 0.8)`
    },
    containerOuter: {
      borderRadius: "1rem",
      marginLeft: reportVariant === "mobile" ? "-1rem" : undefined,
      marginRight: reportVariant === "mobile" ? "-1rem" : undefined,
      boxShadow:
        reportVariant === "pdf"
          ? undefined
          : `inset 0 0 2rem rgba(${ivoryColor.red()}, ${ivoryColor.green()}, ${ivoryColor.blue()}, 0.15)`
    },
    achieveGoal: {
      marginTop: "0rem",
      marginBottom: "0.5rem",
      fontWeight: "bold"
    },
    learnWoop: {
      marginTop: "0rem"
    },
    coachCorner: {
      fontWeight: "bold",
      marginTop: "1rem",
      marginBottom: "0.5rem"
    },
    coachCornerDetails: {
      marginTop: "0rem"
    },
    videoOuterContainer: {
      width: reportVariant === "mobile" ? "100%" : "10.80rem",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "0.5rem"
    },
    videoInnerContainer: {
      width: reportVariant === "mobile" ? "calc(100vw - 4rem)" : "10.80rem",
      height: reportVariant === "mobile" ? "calc((100vw - 4rem) * 1.8)" : "19.20rem",
      borderRadius: "0.5rem",
      overflow: "hidden",
      position: reportVariant === "mobile" ? undefined : "relative"
    },
    videoInnerContainerLarge: {
      width: reportVariant === "mobile" ? "calc(100vw - 4rem)" : `${10.8 * 1.5}rem`,
      height: reportVariant === "mobile" ? "calc((100vw - 4rem) * 1.8)" : `${19.2 * 1.5}rem`,
      borderRadius: "0.5rem",
      overflow: "hidden",
      position: reportVariant === "mobile" ? undefined : "relative"
    },
    videoIframe: {
      width: "100%",
      height: "100%",
      borderRadius: "0.5rem"
    },
    woopVideoOuter: {
      width: reportVariant === "mobile" ? "100%" : `${10.8 * 1.5}rem`,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "0.5rem",
      overflow: "hidden"
    },
    woopVideoGrid: {
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      flexDirection: "column"
    },
    backupPicture: {
      width: "10.80rem",
      height: "19.20rem",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      borderRadius: "0.5rem"
    },
    coachCornerDescription: {
      minHeight: reportVariant === "pdf" ? "6rem" : undefined,
      marginRight: "-2rem"
    },
    qrContainer: {
      width: "10.80rem",
      height: "10.80rem",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "1.5rem"
    },
    link: {
      textDecoration: "none"
    }
  })
}
