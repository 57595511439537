import { ViewParameters as CoreViewParameters } from "core/modules/actions/CoreActions"
import { CoreApi, RestApiCallParameters } from "core/modules/api/CoreApi"
import { Localization } from "core/modules/localization/Localization"
import { Doc, SurveyUserDetails } from "core/modules/state/model/Model"
import { ModelManager } from "core/modules/state/model/ModelManager"
import { ViewContainerManager } from "core/modules/state/model/ViewContainerManager"
import { Store } from "core/modules/store/Store"

import { BaseModule } from "../../controller/Module"
import { SurveysApi } from "../api/SurveysApi"

export interface ViewParameters extends CoreViewParameters {
  page?: number
  documentsPerPage?: number
  [id: string]: any
}

export interface SurveyActions {
  /**
   * Get document from remote endpoint.
   */
  getDocument<T extends Doc>(id: string | Record<string, any>, type: string): Promise<T>

  /**
   * Update a document in remote endpoint
   */
  updateDocument(document: Doc, parameters?: RestApiCallParameters): Promise<Response>

  /*
   * Create document in remote endpoint
   */
  createDocument<T extends Doc>(document: Partial<T>, parameters?: RestApiCallParameters): Promise<T>

  /*
   * Create document in remote endpoint
   */
  removeDocument(document: Doc | string, parameters?: RestApiCallParameters): Promise<Response>

  getView<T extends Doc>(viewName: string, parameters?: ViewParameters): Promise<ViewContainerManager<T>>

  verifyEmail(email: string): Promise<Response>

  validateEmailCheck(email: string, companyCode: string, surveyId: string): Promise<Response>

  validateEmailToken(email: string, token: string): Promise<Response>

  updateUserDetails(userDetails: SurveyUserDetails): Promise<Response>

  updateReportExtra(reportId: string, answerId: string, reportExtra: any): Promise<Response>
}

export class SurveyActionsModule extends BaseModule implements SurveyActions {
  declare coreApi: CoreApi
  declare localization: Localization
  declare modelManager: ModelManager
  declare store: Store
  declare surveysApi: SurveysApi

  get moduleName() {
    return "SurveyActions"
  }

  get dependencies() {
    return ["CoreApi", "Localization", "ModelManager", "Store", "SurveysApi"]
  }

  getDocument<T extends Doc>(id: string | Record<string, any>, documentType: string): Promise<T> {
    const params = typeof id === "string" ? { id } : { ...id }

    return this.coreActions.getDocument<T>(this.surveysApi, {
      ...params,
      type: documentType,
      acceptLanguage: this.acceptLanguage
    })
  }

  getView<T extends Doc>(viewName: string, parameters?: ViewParameters): Promise<ViewContainerManager<T>> {
    parameters ||= {}
    parameters.acceptLanguage ||= this.acceptLanguage
    parameters.type = viewName

    return this.coreActions.getView<T>(this.surveysApi, parameters)
  }

  createDocument<T extends Doc>(document: Partial<T>, parameters: RestApiCallParameters): Promise<T> {
    parameters ||= {}
    parameters.acceptLanguage ||= this.acceptLanguage
    parameters.type ||= document.__type
    parameters.body = document

    return this.coreActions.createDocument<T>(this.surveysApi, parameters)
  }

  removeDocument(document: Doc, parameters: RestApiCallParameters): Promise<Response> {
    parameters ||= {}
    parameters.acceptLanguage ||= this.acceptLanguage

    if (typeof document === "string") {
      parameters.id = document
    } else {
      parameters.id = document.id
      parameters.type ||= document.__type
    }

    return this.coreActions.removeDocument(this.surveysApi, parameters)
  }

  updateDocument(document: Doc, parameters: RestApiCallParameters): Promise<Response> {
    parameters ||= {}
    parameters.acceptLanguage ||= this.acceptLanguage
    parameters.body = document
    parameters.type ||= document.__type

    return this.coreActions.updateDocument(this.surveysApi, document, parameters)
  }

  updateUserDetails(userDetails: SurveyUserDetails): Promise<Response> {
    const parameters: RestApiCallParameters = {
      acceptLanguage: this.acceptLanguage,
      apiCallType: "update",
      body: {
        user_name: userDetails.user_name,
        user_country: userDetails.user_country,
        user_birth_year: userDetails.user_birth_year,
        user_height: userDetails.user_height,
        user_weight: userDetails.user_weight
      },
      type: "UserUpdate"
    }
    return this.surveysApi.restApiCall(parameters)
  }

  verifyEmail(email: string): Promise<Response> {
    const parameters: RestApiCallParameters = {
      acceptLanguage: this.acceptLanguage,
      apiCallType: "update",
      body: { email },
      type: "EmailVerify"
    }
    return this.surveysApi.restApiCall(parameters)
  }

  validateEmailCheck(emailAddress: string, companyCode: string, surveyId: string): Promise<Response> {
    const parameters: RestApiCallParameters = {
      acceptLanguage: this.acceptLanguage,
      apiCallType: "get",
      email: emailAddress,
      company_code: companyCode,
      id: surveyId,
      survey_id: surveyId,
      search: { email: emailAddress, company_code: companyCode, survey_id: surveyId },
      type: "EmailValidateCheck"
    }
    return this.surveysApi.restApiCall(parameters)
  }

  validateEmailToken(emailAddress: string, tokenValue: string): Promise<Response> {
    const parameters: RestApiCallParameters = {
      acceptLanguage: this.acceptLanguage,
      apiCallType: "create",
      email: emailAddress,
      magic_token: tokenValue,
      grant_type: "magic_token",
      search: [{ email: emailAddress }, { magic_token: tokenValue }, { grant_type: "magic_token" }],
      type: "EmailValidateToken"
    }
    return this.surveysApi.restApiCall(parameters)
  }

  updateReportExtra(reportId: string, answerId: string, reportExtra: any) {
    const parameters: RestApiCallParameters = {
      acceptLanguage: this.acceptLanguage,
      apiCallType: "update",
      reportId,
      answerId,
      reportExtra: reportExtra,
      type: "SurveyResultsExtra",
      body: { report_extra: reportExtra }
    }
    return this.surveysApi.restApiCall(parameters)
  }

  private get acceptLanguage() {
    return this.localization.currentLanguage
  }
}
