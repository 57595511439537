import { LogicComponentProps } from "core/components/base/LogicComponent"

import { LogicComponent } from "../base/LogicComponent"
import Dropdown from "../form/Dropdown"

interface LanguageSelectorDropdownProps extends LogicComponentProps {
  language: string
  setLanguage: (language: string) => void
  id?: string
  className?: string
}

export default class LanguageSelectorDropdown extends LogicComponent<LanguageSelectorDropdownProps, {}> {
  get componentName(): string[] {
    return ["settings", "LanguageSelectorDropdown"]
  }

  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    const { language, id, setLanguage } = this.props

    const languages = this.localization.enabledLanguages

    return (
      <Dropdown
        options={languages.map(language => ({
          label: `${this.txt("languages", language, "name")} (${this.txt("languages", language, "short_name")})`,
          value: language
        }))}
        value={language}
        allowEmptySelection={false}
        label={this.txt("chooseLanguage")}
        id={id || "languageSelection"}
        onChange={value => {
          setLanguage(value)
        }}
      />
    )
  }
}
