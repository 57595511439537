import { type PropsWithChildren, useState } from "react"

import { printPdf } from "lib/services/pdf"
import Button, { type ButtonProps } from "lib/ui/components/form/Button"
import { downloadBlob } from "lib/utils/FileUtils"
import posthog from "posthog-js"

interface Props extends ButtonProps {
  filename?: string
  url: string
}

export function DownloadPdfButton({ children, filename, url, ...props }: PropsWithChildren<Props>) {
  const [isLoading, setIsLoading] = useState(false)

  const handleDownload = async () => {
    setIsLoading(true)

    posthog.capture("download_pdf", { url })

    const [pdf, error] = await printPdf(url)

    posthog.capture("download_pdf_complete", { url })

    setIsLoading(false)

    if (error) {
      posthog.capture("download_pdf_failed", { url, error })
      alert(`Download failed: ${error}`)
    } else if (pdf) {
      downloadBlob(pdf, filename || "download.pdf")
    }
  }

  return (
    <Button loading={isLoading} onClick={handleDownload} {...props}>
      {children}
    </Button>
  )
}
