import Typography from "@material-ui/core/Typography"
import * as csx from "csx"
import moment, { Moment } from "moment"
import { style } from "typestyle"

import { PlannedSurvey, Survey } from "app/surveys/modules/state/model/Model"
import { ViewComponentProps } from "core/components/base/ViewComponent"
import Button from "lib/ui/components/form/Button"
import CardLayout from "lib/ui/components/layout/CardLayout"

import { ViewComponent } from "../../../base/ViewComponent"

interface ThankYouPageViewProps extends ViewComponentProps {
  resultMessage?: string
  plannedSurveys: PlannedSurvey[]
  thankYouImage: string
  showReportButton: boolean
  logo: string
  onReport: () => void
  onSelectSurvey?: (plannedSurveyId: string) => void
}

export default class ThankYouPageView extends ViewComponent<ThankYouPageViewProps, {}> {
  get componentName() {
    return ["survey", "pages", "thank_you", "ThankYouPageView"]
  }

  get logoContainerClass() {
    return style({
      display: "flex",
      flexFlow: "row-reverse"
    })
  }

  get headerClass() {
    return style({
      margin: "2.5rem 2.5rem 0 2.5rem"
    })
  }

  get outerContainerClass() {
    return style({
      display: "flex",
      flexDirection: "column",
      justifyContent: "center"
    })
  }

  get contentClass() {
    return style({
      margin: "1rem 2rem",
      display: "flex",
      flexDirection: "column",
      textAlign: this.isMobile ? undefined : "center"
    })
  }

  get paragraphClass() {
    return style({
      marginTop: "1em",
      textAlign: this.isMobile ? undefined : "center",
      alignSelf: "center",
      maxWidth: "35rem"
    })
  }

  get logoClass() {
    return style({
      width: "10rem",
      marginBottom: "2rem"
    })
  }

  render() {
    super.render()

    return this.isMobile ? this.renderMobile() : this.renderDesktop()
  }

  constructor(p) {
    super(p)

    // Allow exiting app without warning
    window.onbeforeunload = null
  }

  private renderMobile = this.renderMain

  private renderDesktop() {
    return (
      <CardLayout width="50rem" background={this.theme.background} headerBackgroundColor={this.theme.colors.primary}>
        {this.renderMain()}
      </CardLayout>
    )
  }

  private renderMain() {
    const { showReportButton, thankYouImage, resultMessage } = this.props

    const descriptionClass = style({
      textAlign: this.isMobile ? undefined : "center"
    })

    const imageClass = style({
      width: "15rem",
      alignSelf: "center",
      marginTop: "1rem",
      marginBottom: "3.5rem",
      opacity: "0.75"
    })

    return (
      <div>
        <div className={this.outerContainerClass}>
          {this.renderHeader()}
          <div className={this.contentClass}>
            <img src={thankYouImage} className={imageClass} />
            <Typography className={descriptionClass} component="h1" variant="h3">
              {this.txt("title")}
            </Typography>
            {resultMessage && (
              <p className={this.paragraphClass}>
                <em>{resultMessage}</em>
              </p>
            )}
          </div>

          <div className={this.contentClass}>
            {this.props.plannedSurveys?.length > 0 && this.renderRemainingSurveys()}
            {showReportButton && this.renderReportButton()}
          </div>
        </div>
      </div>
    )
  }

  private renderHeader() {
    const { logo } = this.props

    return (
      <div className={this.headerClass}>
        <div className={this.logoContainerClass}>
          <img alt="Hintsa" className={this.logoClass} src={logo} />
        </div>
      </div>
    )
  }

  private renderReportButton() {
    const buttonClass = style({
      minHeight: "2.5em",
      marginLeft: csx.important("0"),
      padding: csx.important("0 1em")
    })

    const buttonContainerClass = style({
      display: "flex",
      justifyContent: "center",
      marginTop: "1em"
    })

    return (
      <div className={buttonContainerClass}>
        <Button className={buttonClass} fullWidth={false} onClick={this.props.onReport}>
          {this.txt("show_results")}
        </Button>
      </div>
    )
  }

  private renderRemainingSurveys() {
    return (
      <div className={this.contentClass}>
        <div>{this.txt("surveys_remaining")}</div>
        {this.renderSurveys()}
      </div>
    )
  }

  private renderSurveys() {
    const surveyContainerClass = style({
      width: "100%",
      borderColor: this.theme.colors.emphasizeColor2.toString(),
      color: this.theme.colors.emphasizeColor2.toString(),
      borderStyle: "solid",
      padding: "2rem",
      marginBottom: "1em",
      minWidth: "20rem",
      cursor: "pointer",
      transition: "color 0.2s ease; background-color 0.2s ease",
      $nest: {
        "&:hover": {
          borderColor: this.theme.colors.emphasizeColor2.lighten(0.3, true).toHexString(),
          color: this.theme.colors.emphasizeColor2.lighten(0.3, true).toHexString()
        }
      }
    })

    const surveyPlaceholderClass = style({ margin: "2em 0 2em 0" })

    const surveys = this.props.plannedSurveys.map(plannedSurvey => {
      const organisationSurvey = plannedSurvey.organisation_survey
      const surveyTitleText = organisationSurvey?.title ? `${organisationSurvey.title}, ` : ""

      // when a survey is closed, we give it a ends_on date; until a survey is closed, we give
      // it an ended date of "100 years in the future" (so date-range-based SQL queries still
      // work for reporting). Anything ending after the year 2100 is basically ongoing (not closed)
      const surveyUnEndedDate = moment("01-01-2100", "MM-DD-YYYY")
      const surveyEndDate =
        organisationSurvey?.ends_on.year < surveyUnEndedDate.year
          ? organisationSurvey.ends_on.format(this.localization.dateFormat)
          : ""

      const surveyRangeDesc = surveyEndDate
        ? `${organisationSurvey.started_at.format(this.localization.dateFormat)} - ${surveyEndDate}`
        : `started ${organisationSurvey.started_at.format(this.localization.dateFormat)}`
      const scheduleText = organisationSurvey?.title ? ` (${surveyRangeDesc})` : ""

      return (
        <div
          key={plannedSurvey?.id}
          className={surveyContainerClass}
          onClick={() => {
            this.props.onSelectSurvey(plannedSurvey.id)
          }}
        >
          {`${surveyTitleText}${this.txt((plannedSurvey.survey as Survey).title)}${scheduleText}`}
          {this.showLastCompletedDate(plannedSurvey)}
        </div>
      )
    })

    if (surveys.length === 0) {
      surveys.push(<div className={surveyPlaceholderClass} />)
    }

    return surveys
  }

  private showLastCompletedDate(plannedSurvey: PlannedSurvey) {
    // note: this is also repeated in the ThankYouPageView
    if (!plannedSurvey?.last_completed) {
      return this.showLastStartedDate(plannedSurvey)
    }
    return ` (${this.txt("last_completed", { date: this.renderDate(plannedSurvey.last_completed) })})`
  }

  private showLastStartedDate(plannedSurvey: PlannedSurvey) {
    if (!plannedSurvey?.last_started) {
      return null
    }
    return ` (${this.txt("last_started", { date: this.renderDate(plannedSurvey.last_started) })})`
  }

  private renderDate(this_date: Moment) {
    if (!this_date) return null
    // write the date out using the current locale
    return moment(this_date).format(this.localization.dateFormat)
  }
}
