import { Team } from "app/surveys/modules/state/model/Model"
import { LogicComponentProps } from "core/components/base/LogicComponent"
import Dialog from "lib/ui/components/dialogs/Dialog"

import { LogicComponent } from "../../../base/LogicComponent"
import TeamSelection from "./TeamSelectionStructure"

interface TeamSelectionDialogProps extends LogicComponentProps {
  onClose: () => void
  onSelectTeam?: (teamId: string) => void
  allowParentTeamSelection?: boolean
  teams: Team[]
}

export default class TeamSelectionDialog extends LogicComponent<TeamSelectionDialogProps> {
  render() {
    super.render()

    const { teams, allowParentTeamSelection, onClose, onSelectTeam } = this.props

    return (
      <Dialog
        size="medium"
        padding="wide"
        okButtonText={this.txt("core", "components", "ui", "form", "close")}
        onClose={onClose}
      >
        <TeamSelection teams={teams} allowParentTeamSelection={allowParentTeamSelection} onSelectTeam={onSelectTeam} />
      </Dialog>
    )
  }
}
