import { PlannedSurvey, ReportKey, Survey, SurveyResult } from "app/surveys/modules/state/model/Model"
import { LogicComponentProps } from "core/components/base/LogicComponent"

import { LogicComponent } from "../../../base/LogicComponent"
import { surveysWithReport } from "../../base/Survey"
import ThankYouPageView from "./ThankYouPageView"

interface ThankYouPageProps extends LogicComponentProps {
  onSelectSurvey: (plannedSurveyId: string) => void
  plannedSurveys: PlannedSurvey[]
  plannedSurvey: PlannedSurvey
  showConclusion: boolean
  survey: Survey
  surveyResult: SurveyResult
  language: string
}

export default class ThankYouPage extends LogicComponent<ThankYouPageProps> {
  get componentName() {
    return ["survey", "pages", "thank_you", "ThankYouPage"]
  }

  openReport = () => {
    const { language, surveyResult } = this.props

    location.href = `${location.origin}/report/${surveyResult.uuid}?language=${language}`
  }

  render() {
    super.render()

    const { onSelectSurvey, plannedSurveys } = this.props

    return (
      <ThankYouPageView
        logo={`${this.appConfig.imagesRoot}/hintsa/logo_hintsa_black.svg`}
        thankYouImage={`${this.appConfig.imagesRoot}/cobl_plain.svg`}
        resultMessage={this.getThankYouMessage()}
        plannedSurveys={plannedSurveys}
        showReportButton={this.hasReport()}
        onSelectSurvey={onSelectSurvey}
        onReport={this.openReport}
      />
    )
  }

  private hasReport() {
    const { survey } = this.props

    return surveysWithReport.includes(survey?.report_key?.toLowerCase())
  }

  private getThankYouMessage() {
    const { survey } = this.props

    switch (survey.report_key.toLowerCase() as ReportKey) {
      case "hintsa":
      case "wellbeing_test":
      case "wellbeing_start":
      case "wellbeing_end":
      case "wellbeing_general":
      case "wellbeing_pulse":
        return this.txt("wellbeing_survey_thank_you")
      default:
        return undefined
    }
  }
}
