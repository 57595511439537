import Box from "@material-ui/core/Box"
import moment from "moment"
import * as queryString from "query-string"

import { SurveyActions } from "app/surveys/modules/actions/SurveyActions"
import {
  Answer,
  Consent,
  OrganisationSurveyTag,
  PlannedSurvey,
  Question,
  ReportKey,
  Survey,
  SurveyAnswerInstance,
  Survey as SurveyModel,
  SurveyResult,
  SurveyState,
  openLinkSurveyType,
  surveyStateType
} from "app/surveys/modules/state/model/Model"
import PrivacyBanner from "app/surveys_app/components/survey/banners/PrivacyBanner"
import { LogicComponentProps, LogicComponentState } from "core/components/base/LogicComponent"
import { LocalizationState, SurveyUserDetails, defaultDocumentId } from "core/modules/state/model/Model"
import { AuthenticationActions } from "lib/authentication/modules/actions/AuthenticationActions"
import BlockDiv from "lib/ui/components/layout/BlockDiv"
import posthog from "posthog-js"
import shortid from "shortid"
import { LogicComponent } from "../../base/LogicComponent"
import PersonalDetailsPage, { PersonalDetailsResults } from "../pages/personal_details/PersonalDetailsPage"
import QuestionsPage from "../pages/questions/QuestionsPage"
import SelectSurveyPage from "../pages/select_survey/SelectSurveyPage"
import StartPage from "../pages/start/StartPage"
import SurveyCodePage from "../pages/survey_code/SurveyCodePage"
import ThankYouPage from "../pages/thank_you/ThankYouPage"
import ValidateEmailPage from "../pages/validate_email/ValidateEmailPage"

interface SearchParams {
  email?: string
  cc?: string
  id?: string
  language?: string
  token?: string
  openLink?: "1"
  survey?: string
}

type SurveyPhase =
  | "surveyCode"
  | "selectSurvey"
  | "start"
  | "personalDetails"
  | "emailValidate"
  | "questions"
  | "thankYou"
  | "notFound"
  | "error"
  | "alreadyCompleted"
  | "surveyClosed"

interface SurveyProps extends LogicComponentProps {
  classes?: any
}

interface State extends LogicComponentState {
  shownSurveyPhase?: SurveyPhase
  page?: number
  lastPageVisited?: number
  questionsAnsweredWithNoneOfTheAbove: { [key: string]: boolean }
  showBlockScreen?: boolean
  surveyCodeError?: any
  emailValidError?: string
  languageSelectionShown?: boolean
  languageChanged?: boolean
}

interface AnswerUpdate {
  options?: {
    id: string
    note?: string
  }[]
  question_id: number
}

interface ResultUpdate {
  __type: string
  answers: AnswerUpdate[]
  complete: boolean
}

export const surveysWithReport: ReportKey[] = [
  "hintsa",
  "wellbeing_test",
  "wellbeing_start",
  "wellbeing_end",
  "wellbeing_general",
  "wellbeing_pulse"
]

const surveysWithPrivacyBanner: ReportKey[] = [
  "hintsa",
  "wellbeing_test",
  "wellbeing_start",
  "wellbeing_end",
  "wellbeing_general",
  "wellbeing_pulse"
]

export default class SurveyApp extends LogicComponent<SurveyProps, State> {
  declare authenticationActions: AuthenticationActions
  declare surveyActions: SurveyActions

  get componentName(): string[] {
    return ["survey", "SurveyApp"]
  }

  get dependencies(): string[] {
    return ["AuthenticationActions", "SurveyActions"]
  }

  private logoutTimeout

  // Set to true if answer updates are not sent to server due to congestion.
  private questionUpdatesHalted: boolean

  constructor(props) {
    super(props)

    this.state = { lastPageVisited: 0, questionsAnsweredWithNoneOfTheAbove: {} }

    this.setDefault<SurveyState>({}, surveyStateType)

    this.modelManager.events.on("transactionEnded", () => {
      this.forceUpdate()
    })

    document.title = this.txt("title")
  }

  componentDidMount() {
    const params = this.getParams()

    posthog.capture("survey_app_loaded")

    if (!this.validateParams(params)) return

    if (params.email) {
      this.startAppWithEmail(params)
    } else if (params.cc && params.openLink !== "1") {
      this.startAppWithCompanyCode(params)
    } else if (params.openLink) {
      this.startAppWithOpenLink(params)
    } else {
      posthog.capture("invalid_parameters")
      this.logger.error("Invalid or missing parameters, cannot start survey.")
    }
  }

  render() {
    return (
      <Box display="flex" flexDirection="column" height="100%" minHeight="100vh">
        {this.renderOverlayItems()}
        {this.renderBanner()}
        {this.renderContent()}
      </Box>
    )
  }

  private renderOverlayItems() {
    return this.state.showBlockScreen ? (
      <BlockDiv showProgress={true} progressTimeout={this.appConfig.spinnerDelay} />
    ) : undefined
  }

  private renderBanner() {
    const surveyState = this.docDefault<SurveyState>("SurveyState")
    const survey = this.doc<SurveyModel>(surveyState.surveyId, "Survey")

    return surveysWithPrivacyBanner.includes(survey?.report_key?.toLowerCase()) && !this.isAnonymousSurvey() ? (
      <PrivacyBanner />
    ) : undefined
  }

  private renderContent() {
    const surveyState = this.docDefault<SurveyState>("SurveyState")

    const plannedSurvey = this.doc<PlannedSurvey>(surveyState.plannedSurveyId, "PlannedSurvey")
    const survey = this.doc<SurveyModel>(surveyState.surveyId, "Survey")
    const localizationState = this.docDefault<LocalizationState>("LocalizationState")
    const openLinkSurvey = this.doc<PlannedSurvey>(surveyState.openLinkSurveyId, openLinkSurveyType)

    if (
      !["surveyCode", "selectSurvey", "notFound", "error", "alreadyCompleted", "surveyClosed"].includes(
        this.state.shownSurveyPhase
      ) &&
      !((plannedSurvey || openLinkSurvey) && survey)
    ) {
      return <div />
    }

    const language = localizationState.language

    switch (this.state.shownSurveyPhase) {
      case "error":
      case "notFound":
      case "alreadyCompleted":
      case "surveyClosed":
        return this.renderPageStartWithError(this.state.shownSurveyPhase, language)
      case "questions":
        return this.renderPageQuestions(survey, language)
      case "selectSurvey":
        return this.renderPageSelectSurvey(language)
      case "start":
        return this.renderPageStart(survey, plannedSurvey ?? openLinkSurvey, language)
      case "surveyCode":
        return this.renderPageSurveyCode(language)
      case "thankYou":
        return this.renderPageThankYou(survey, language, plannedSurvey ?? openLinkSurvey)
      case "personalDetails":
        return this.renderPagePersonalDetails(plannedSurvey ?? openLinkSurvey, survey, language)
      case "emailValidate":
        return this.renderPageEmailValidate(language, plannedSurvey ?? openLinkSurvey, survey)
      default:
        return undefined
    }
  }

  renderPagePersonalDetails(plannedSurvey: PlannedSurvey, survey: Survey, language: string) {
    const surveyState = this.docDefault<SurveyState>("SurveyState")

    surveyState.plannedSurveyId

    return (
      <PersonalDetailsPage
        plannedSurvey={plannedSurvey}
        survey={survey}
        maxPage={surveyState.maxPage}
        language={language}
        onNext={this.onPersonalDetailsNext}
        onSetLanguage={this.onSetLanguage}
        onLogout={this.logout}
      />
    )
  }
  renderPageEmailValidate(language: any, plannedSurvey: PlannedSurvey, survey: Survey) {
    const surveyState = this.docDefault<SurveyState>("SurveyState")
    return (
      <ValidateEmailPage
        plannedSurvey={plannedSurvey}
        survey={survey}
        maxPage={surveyState.maxPage}
        language={language}
        email={surveyState.email}
        onSetLanguage={this.onSetLanguage}
        onLogout={this.logout}
        onNext={this.onEmailValidateNext}
        onPrevious={this.onOpenLinkStart}
        onRetry={this.retryEmailValidationCheck}
        onTokenChanged={this.onTokenChanged}
        error={this.state.emailValidError}
      />
    )
  }

  renderPageThankYou(survey: Survey, language: any, plannedSurvey: PlannedSurvey) {
    const surveyState = this.docDefault<SurveyState>("SurveyState")
    const plannedSurveys = this.view<PlannedSurvey>("PlannedSurveys")
      .documents.map(plannedSurvey => ({
        ...plannedSurvey,
        survey: this.doc(plannedSurvey.survey)
      }))
      // In ongoing surveys, the current survey is not shown in the list of planned surveys
      .filter(plannedSurvey => plannedSurvey.survey.id !== survey.id)

    return (
      <ThankYouPage
        survey={survey}
        plannedSurveys={(surveyState.loginType !== "openLink" && plannedSurveys) || []}
        plannedSurvey={plannedSurvey}
        language={language}
        showConclusion={surveyState.loginType !== "openLink"}
        onSelectSurvey={this.onSelectSurvey}
        surveyResult={this.doc<SurveyResult>(surveyState.surveyResultId, "SurveyResult")}
      />
    )
  }
  renderPageSurveyCode(language: any) {
    return (
      <SurveyCodePage
        id="surveyCodePage"
        language={language}
        error={this.txt(this.state.surveyCodeError)}
        showLanguageSelection={!this.state.languageSelectionShown}
        onLogin={this.loginWithSurveyCode}
        onSetLanguage={this.onSetLanguage}
      />
    )
  }
  renderPageStart(survey: Survey, plannedSurvey: PlannedSurvey, language: any) {
    const surveyState = this.docDefault<SurveyState>("SurveyState")

    return (
      <StartPage
        survey={survey}
        language={language}
        onSetLanguage={this.onSetLanguage}
        showLanguageSelection={!this.state.languageSelectionShown}
        onStart={this.onStartSurvey}
        customLogo={plannedSurvey?.organisation_survey?.custom_logo}
        customWelcomeMessage={plannedSurvey?.organisation_survey?.custom_welcome}
        showConsent={!this.isAnonymousSurvey()}
      />
    )
  }
  renderPageSelectSurvey(language: any) {
    return (
      <SelectSurveyPage
        language={language}
        plannedSurveys={this.view<PlannedSurvey>("PlannedSurveys").documents}
        onLogout={this.logout}
        onSetLanguage={this.onSetLanguage}
        onSelectSurvey={this.onSelectSurvey}
      />
    )
  }

  renderPageQuestions(survey: Survey, language: any) {
    const surveyState = this.docDefault<SurveyState>("SurveyState")

    return (
      <QuestionsPage
        survey={survey}
        surveyResult={this.doc<SurveyResult>(surveyState.surveyResultId, "SurveyResult")}
        lastAnsweredQuestionIndex={surveyState.lastAnsweredQuestionIndex}
        firstQuestionToShow={this.getFirstQuestionIndexForPage(this.state.page)}
        lastQuestionToShow={this.getLastQuestionIndexForPage(this.state.page)}
        page={this.state.page}
        maxPage={surveyState.maxPage}
        language={language}
        allowPreviousOnFirstPage={this.arePersonalDetailsShown()}
        onAnswer={this.onQuestionAnswered}
        onQuestionAnswerNoneOfTheAbove={this.onQuestionAnswerNoneOfTheAbove}
        onOptionSelected={this.onQuestionOptionSelected}
        onOptionsSelected={this.onQuestionOptionsSelected}
        onDone={this.onDone}
        onPrevious={this.onPreviousQuestionPage}
        onNext={this.onNextQuestionPage}
        onSetLanguage={this.onSetLanguage}
        onLogout={this.logout}
      />
    )
  }
  renderPageStartWithError(error: "notFound" | "error" | "alreadyCompleted" | "surveyClosed", language: string) {
    return (
      <StartPage
        error={error}
        language={language}
        showLanguageSelection={!this.state.languageSelectionShown}
        showConsent={!this.isAnonymousSurvey()}
        onSetLanguage={this.onSetLanguage}
      />
    )
  }

  private getParams(): SearchParams {
    const params = queryString.parse(location.search)

    const results = {}
    for (const key of Object.keys(params)) {
      results[key] = Array.isArray(params[key]) ? params[key][0] : params[key]
    }

    return results
  }

  private validateParams(params) {
    if (params.email && !params.token) {
      posthog.capture("token_missing")
      this.logger.error("Token missing from querystring")
      return
    }

    if (params.email && !params.id) {
      posthog.capture("survey_id_missing")
      this.logger.error("Survey id missing from querystring")
      return
    }

    if (params.email && !params.language) {
      posthog.capture("language_missing")
      this.logger.error("Language missing from querystring")
      return
    }

    if (params.email && params.openLink === "1") {
      posthog.capture("cannot_combine_openlink_with_email")
      this.logger.error("Cannot combine openLink survey with email")
      return
    }

    if (!params.email && !params.cc) {
      posthog.capture("no_email_or_company_code")
      this.logger.error("No email or company code provided")
      return
    }

    if (params.openLink === "1" && !params.survey) {
      posthog.capture("no_survey_for_openlink")
      this.logger.error("No survey provided for openLink survey")
      return
    }

    if (params.openLink === "1" && !params.cc) {
      posthog.capture("no_company_for_openlink")
      this.logger.error("No company provided for openLink survey")
      return
    }

    return true
  }

  private loginWithSurveyCode = async (surveyCode: string) => {
    const { languageChanged } = this.state

    posthog.capture("login_with_survey_code")

    this.startSpinner()
    const surveyState = this.docDefault<SurveyState>("SurveyState")

    try {
      const result = await this.authenticationActions.loginWithSurveyCode(surveyState.companyCode, surveyCode)
      if (!languageChanged) this.setLanguageBasedOnServerResponse(result)

      this.triggerLogoutTimer()

      const surveys = (await this.surveyActions.getView<PlannedSurvey>("PlannedSurveys")).documents.map(
        plannedSurvey => ({
          ...plannedSurvey,
          survey: this.doc<SurveyModel>(plannedSurvey.survey.id, "Survey")
        })
      )

      const surveyInProps = surveys.find(survey => survey.id === surveyState.surveyId)

      if (surveys.length === 1) {
        await this.loadSurveyData(surveys[0].id!)
      } else if (surveyInProps) {
        await this.loadSurveyData(surveyInProps.id!)
      } else {
        posthog.capture("$pageview", { $current_url: "/selectSurvey" })
        this.updateState(state => {
          state.surveyCodeError = undefined
          state.shownSurveyPhase = "selectSurvey"
          state.showBlockScreen = false
        })
      }

      posthog.capture("login_with_survey_code_complete")

      this.updateState(state => (state.languageSelectionShown = true))
    } catch (e) {
      this.updateState(state => {
        posthog.capture("login_with_survey_code_failed")

        state.surveyCodeError = e.error || "login_error"
        state.showBlockScreen = false
      })
    }
  }

  private setLanguageBasedOnServerResponse(response) {
    let language: string

    try {
      language = response.headers.get("Content-Language")
    } catch (e) {}

    if (language) {
      this.updateDefault<LocalizationState>("LocalizationState", localizationState => {
        localizationState.language = language
      })
    }
  }

  private async startAppWithEmail(params: SearchParams) {
    posthog.capture("start_app_with_email")

    this.updateDefault<LocalizationState>("LocalizationState", localizationState => {
      localizationState.language = params.language
    })

    this.updateDefault<SurveyState>(surveyStateType, surveyState => {
      surveyState.loginType = "email"
    })

    try {
      await this.authenticationActions.loginWithToken(params.token, params.email, false)
    } catch (e) {
      return this.handleError(e)
    }

    await this.loadSurveyData(params.id)
    await this.surveyActions.verifyEmail(params.email)
  }

  private async startAppWithCompanyCode(params: SearchParams) {
    posthog.capture("start_app_with_company_code")

    this.updateDefault<SurveyState>(surveyStateType, surveyState => {
      surveyState.loginType = "companyCode"
      surveyState.companyCode = params.cc
      surveyState.surveyId = params.survey
    })
    this.setState({ shownSurveyPhase: "surveyCode" }, () =>
      posthog.capture("$pageview", { $current_url: "/surveyCode" })
    )
  }

  private async startAppWithOpenLink(params: SearchParams) {
    posthog.capture("start_app_with_open_link")

    this.startSpinner()

    const companyCode = params.cc
    const openLinkSurveyId = params.survey
    try {
      const openLinkSurvey = await this.surveyActions.getDocument<PlannedSurvey>(
        { id: openLinkSurveyId, companyCode },
        openLinkSurveyType
      )

      if (openLinkSurvey.organisation_survey?.organisation_name?.toLowerCase().includes("[demo]")) {
        posthog.identify(`[test]_${shortid.generate()}`, { testUser: true })
      }

      const survey = this.doc<Survey>(openLinkSurvey.survey.id, "Survey")

      this.updateDefault<SurveyState>(surveyStateType, surveyState => {
        surveyState.loginType = "openLink"
        surveyState.companyCode = companyCode
        surveyState.surveyId = survey!.id
        surveyState.openLinkSurveyId = openLinkSurveyId
        surveyState.lastAnsweredQuestionIndex = -1
        surveyState.maxPage = Math.max(0, this.getQuestionCategories(survey!).length - 1)
      })

      this.setState({ page: 0, shownSurveyPhase: "start" }, () =>
        posthog.capture("$pageview", { $current_url: "/start" })
      )

      document.title = this.txt(survey!.title)
    } catch (e) {
      this.handleError(e)
    } finally {
      this.stopSpinner()
    }
  }

  private arePersonalDetailsShown(plannedSurveyId?: string): boolean {
    const surveyState = this.docDefault<SurveyState>("SurveyState")

    const plannedSurvey = surveyState?.openLinkSurveyId
      ? this.doc<PlannedSurvey>(surveyState?.openLinkSurveyId, openLinkSurveyType)
      : this.doc<PlannedSurvey>(plannedSurveyId ?? surveyState?.plannedSurveyId, "PlannedSurvey")

    const organisationSurveyTags = plannedSurvey?.organisation_survey?.organisation_survey_tags

    return organisationSurveyTags?.some(tag =>
      [
        OrganisationSurveyTag.AskUserEmail,
        OrganisationSurveyTag.AskUserName,
        OrganisationSurveyTag.SelectTeam,
        OrganisationSurveyTag.AskUserHeight,
        OrganisationSurveyTag.AskUserWeight,
        OrganisationSurveyTag.SelectTags
      ].includes(tag as OrganisationSurveyTag)
    )
  }

  private isAnonymousSurvey(): boolean {
    const surveyState = this.docDefault<SurveyState>("SurveyState")

    if (!surveyState?.openLinkSurveyId) return false

    const plannedSurvey = this.doc<PlannedSurvey>(surveyState?.openLinkSurveyId, openLinkSurveyType)
    const organisationSurveyTags = plannedSurvey?.organisation_survey?.organisation_survey_tags

    return !organisationSurveyTags?.some(
      tag =>
        [OrganisationSurveyTag.AskUserEmail, OrganisationSurveyTag.AskUserName].includes(
          tag as OrganisationSurveyTag
        ) || organisationSurveyTags.includes(OrganisationSurveyTag.AnonymousSurvey)
    )
  }

  private async loadSurveyData(plannedSurveyID: string) {
    this.startSpinner()

    try {
      const plannedSurvey = await this.surveyActions.getDocument<PlannedSurvey>(plannedSurveyID, "PlannedSurvey")
      const survey = this.doc<SurveyModel>(plannedSurvey.survey.id, "Survey")

      const { lastAnsweredQuestionIndex, surveyResultId } = await this.loadIncompleteSurveyResults(
        plannedSurvey,
        survey!
      )
      const hasAnsweredQuestions = lastAnsweredQuestionIndex > -1
      const isSurveyStarted = !!surveyResultId

      if (plannedSurvey.organisation_survey?.organisation_name?.toLowerCase().includes("[demo]")) {
        posthog.identify(`[test]_${shortid.generate()}`, { testUser: true })
      }

      let surveyPhase: SurveyPhase
      if (hasAnsweredQuestions) {
        surveyPhase = "questions"
      } else if (isSurveyStarted) {
        posthog.capture("continue_existing_survey")
      } else if (!isSurveyStarted) {
        surveyPhase = "start"
      } else if (this.arePersonalDetailsShown(plannedSurvey.id)) {
        surveyPhase = "personalDetails"
      } else {
        surveyPhase = "questions"
      }

      this.updateDefault<SurveyState>(surveyStateType, surveyState => {
        surveyState.surveyId = survey!.id
        surveyState.surveyResultId = surveyResultId
        surveyState.plannedSurveyId = plannedSurvey.id
        surveyState.lastAnsweredQuestionIndex = lastAnsweredQuestionIndex
        surveyState.maxPage = Math.max(0, this.getQuestionCategories(survey!).length - 1)
      })

      // Find the page with the first unanswered question
      const page = this.getPageNumberForQuestionByCategory(lastAnsweredQuestionIndex + 1, survey!)

      this.setState(
        {
          shownSurveyPhase: surveyPhase,
          page,
          lastPageVisited: page,
          showBlockScreen: false
        },
        () => posthog.capture("$pageview", { $current_url: `/${surveyPhase}` })
      )

      this.triggerLogoutTimer()

      document.title = this.txt(survey!.title)
    } catch (e) {
      this.handleError(e)
    } finally {
      this.stopSpinner()
    }
  }

  private async loadIncompleteSurveyResults(plannedSurvey: PlannedSurvey, survey: SurveyModel) {
    const surveyResultsView = await this.surveyActions.getView<SurveyResult>("SurveyResults", {
      plannedSurveyId: plannedSurvey.id,
      incomplete: true
    })

    let lastAnsweredQuestionIndex = -1
    const surveyResult = surveyResultsView.documents[0]
    const instance: SurveyAnswerInstance | undefined = surveyResult?.instances?.[0]

    if (!!instance?.answers?.length) {
      const answeredQuestions: string[] = []

      // Build list of answered question ids
      for (const answer of instance.answers) {
        const question = this.getQuestionForAnswer(answer, survey)

        if (question) answeredQuestions.push(question.id!)
      }

      // Get first non-optional unanswered question index
      const firstUnansweredQuestionIndex = survey.questions.findIndex(
        question => !answeredQuestions.includes(question.id!) && question.min_required_options !== 0
      )
      lastAnsweredQuestionIndex = firstUnansweredQuestionIndex - 1

      this.logger.info("Previous survey answers exist. Last answered question index:", lastAnsweredQuestionIndex)
      posthog.capture("continue_previous_survey")
    }

    return { lastAnsweredQuestionIndex, surveyResultId: surveyResult?.id }
  }

  private reload() {
    const { language } = this.docDefault<LocalizationState>("LocalizationState")

    const url = new URL(window.location.href)
    url.searchParams.set("language", language)

    window.location.href = url.href
  }

  private logout = async () => {
    const surveyState = this.docDefault<SurveyState>("SurveyState")
    posthog.capture("logout")

    window.onbeforeunload = null

    await this.authenticationActions.logout()

    if (surveyState.loginType === "companyCode") this.reload()
    else window.location.href = "about:blank"
  }

  private onSetLanguage = (language: string) => {
    this.triggerLogoutTimer()

    posthog.capture("set_language", { language })

    this.updateDefault<LocalizationState>("LocalizationState", localizationState => {
      localizationState.language = language
    })

    this.updateState(state => (state.languageChanged = true))
  }

  private onSelectSurvey = plannedSurveyId => {
    posthog.capture("select_survey", { plannedSurveyId })

    this.loadSurveyData(plannedSurveyId).then(() => {
      // Language selection is only shown once
      this.setState({ languageSelectionShown: true })
    })
  }

  private onStartSurvey = async () => {
    posthog.capture("start_survey")

    const surveyState = this.docDefault<SurveyState>("SurveyState")

    this.startSpinner()

    const survey = this.doc<SurveyModel>(surveyState.surveyId, "Survey")

    if (survey.questions.length === 0) {
      return alert("Error: No questions in this survey")
    }

    posthog.capture("survey_started", { survey_id: survey.id, survey_title: survey.title.en })

    if (surveyState.loginType !== "openLink") {
      posthog.capture("not_open_link_survey")
      const organisation_survey_id = this.doc<PlannedSurvey>(surveyState.plannedSurveyId, "PlannedSurvey")
        ?.organisation_survey.id
      await this.surveyActions.createDocument<Consent>({
        scope: "org_surveys",
        consent_type: "terms_of_service",
        organisation_survey_id: organisation_survey_id,
        __type: "Consent"
      })
    } else {
      posthog.capture("open_link_survey")
      // Open link survey doesn't set answers to backend until completed - use local document
      const surveyResultId = defaultDocumentId
      const surveyResult: SurveyResult = {
        __type: "SurveyResult",
        id: surveyResultId,
        instances: [
          {
            date: moment.utc(),
            answers: survey.questions.map(q => ({ question_id: Number(q.id), options: [] })),
            title: survey.title.string
          }
        ],
        survey: { __type: "Survey", id: survey.id }
      }
      this.set(surveyResult)

      if (this.arePersonalDetailsShown()) {
        this.setState({ shownSurveyPhase: "personalDetails" }, () => {
          posthog.capture("$pageview", { $current_url: "/personalDetails" })
        })
      } else {
        this.setState({ shownSurveyPhase: "questions" }, () => {
          posthog.capture("$pageview", { $current_url: "/questions" })
        })
      }

      this.updateDefault<SurveyState>("SurveyState", surveyState => {
        surveyState.surveyResultId = surveyResultId
      })

      this.stopSpinner()

      return
    }

    const existingSurveyResult = this.doc<SurveyResult>(surveyState.surveyResultId, "SurveyResult")
    if (!existingSurveyResult) {
      try {
        const surveyResult = await this.surveyActions.createDocument<SurveyResult>(
          { __type: "SurveyResult", instances: [] },
          {
            plannedSurveyId: surveyState.plannedSurveyId,
            surveyId: surveyState.surveyId
          }
        )
        this.updateDefault<SurveyState>(surveyStateType, surveyState => {
          surveyState.surveyResultId = surveyResult.id
        })

        const shownSurveyPhase = this.arePersonalDetailsShown() ? "personalDetails" : "questions"
        this.setState({ shownSurveyPhase, showBlockScreen: false }, () =>
          posthog.capture("$pageview", { $current_url: `/${shownSurveyPhase}` })
        )
      } catch {
        // TBD Error messaging
        posthog.capture("getting_survey_results_failed")
        alert("Getting survey results failed, please reload the page.")
      }
    }

    this.stopSpinner()
  }

  private handleError(reason: any) {
    this.logger.warning(`Error: ${reason?.errorDetails ?? reason}`)

    posthog.capture("error", { error: reason?.errorDetails ?? reason })

    let block_screen = true
    let show_survey_phase: SurveyPhase = "error"

    switch (reason.error) {
      case "not_found":
        posthog.capture("survey_not_found")
        block_screen = false
        show_survey_phase = "notFound"
        break

      case "survey_closed":
        posthog.capture("survey_already_closed")
        block_screen = false
        show_survey_phase = "surveyClosed"
        break

      default:
        // nothing to do, default values are generic error
        break
    }

    this.updateDefault<SurveyState>(surveyStateType, surveyState => {
      surveyState.surveyId = undefined
      surveyState.surveyResultId = undefined
      surveyState.plannedSurveyId = undefined
    })

    this.setState((prevState, props) => {
      const newState: State = Object.assign({}, prevState)
      newState.shownSurveyPhase = show_survey_phase
      newState.showBlockScreen = block_screen
      posthog.capture("$pageview", { $current_url: `/${newState.shownSurveyPhase}` })
      return newState
    })
  }

  private getQuestionCategories = (survey: SurveyModel) => [...new Set(survey.questions.map(q => q.category.en))]

  private getQuestionForAnswer(answer: Answer, survey: SurveyModel): Question | undefined {
    const answerOptionIds = answer.options!.map(o => String(o.id))

    return survey.questions.find(q => q.options.some(o => answerOptionIds.includes(String(o.id))))
  }

  private getQuestionsForPage(survey: SurveyModel, page: number): Question[] {
    const surveyState = this.docDefault<SurveyState>("SurveyState")

    const categories = this.getQuestionCategories(survey)

    return survey.questions.filter(q => q.category.en === categories[page])
  }

  private getPageNumberForQuestionByCategory(questionOrIndex: Question | number, survey: SurveyModel) {
    if (questionOrIndex === -1) return 0

    const question = typeof questionOrIndex === "number" ? survey.questions[questionOrIndex] : questionOrIndex

    return this.getQuestionCategories(survey).indexOf(question.category.en)
  }

  private getFirstQuestionIndexForPage(page: number) {
    const surveyState = this.docDefault<SurveyState>("SurveyState")

    const survey = this.doc<SurveyModel>(surveyState.surveyId, "Survey")
    const category = this.getQuestionCategories(survey)[page]
    const categoriesOnly = survey.questions.map(q => q.category.en)

    return categoriesOnly.indexOf(category)
  }

  private getLastQuestionIndexForPage(page: number) {
    const surveyState = this.docDefault<SurveyState>("SurveyState")

    const survey = this.doc<SurveyModel>(surveyState.surveyId, "Survey")
    const category = this.getQuestionCategories(survey)[page]
    const categoriesOnly = survey.questions.map(q => q.category.en)

    return categoriesOnly.lastIndexOf(category)
  }

  private getLastAnsweredQuestionIndexForPage(page: number, answers: Answer[] | AnswerUpdate[]) {
    const surveyState = this.docDefault<SurveyState>("SurveyState")

    const survey = this.doc<SurveyModel>(surveyState.surveyId, "Survey")
    const questions = this.getQuestionsForPage(survey, page)
    const answeredQuestions = answers.map(a => (a.question_id ?? "").toString())
    const firstUnansweredIndex = questions.findIndex(q => !answeredQuestions.includes((q.id ?? "").toString()))

    return Math.max(firstUnansweredIndex - 1, -1)
  }

  private onDone = async () => {
    const surveyState = this.docDefault<SurveyState>("SurveyState")

    this.startSpinner()
    this.triggerLogoutTimer()

    const survey: SurveyModel = this.doc<SurveyModel>(surveyState.surveyId, "Survey")
    const surveyResult: SurveyResult = this.doc<SurveyResult>(surveyState.surveyResultId, "SurveyResult")
    const currentAnswer: SurveyAnswerInstance = surveyResult.instances[0]

    const answers = currentAnswer.answers.map(this.resultAnswerToUpdate)
    const update = {
      __type: "SurveyResult",
      complete: true,
      answers
    }

    posthog.capture("survey_completed")

    this.logger.info("Sending final results", update)

    try {
      if (surveyState.loginType === "openLink") {
        const results = await this.surveyActions.createDocument<SurveyResult>(update, {
          surveyId: surveyState.surveyId,
          openLinkSurveyId: surveyState.openLinkSurveyId,
          companyCode: surveyState.companyCode,
          email: surveyState.email,
          firstName: surveyState.firstName,
          lastName: surveyState.lastName,
          selectedTeam: surveyState.selectedTeam,
          selectedTags: (surveyState.selectedTags ?? []).join(","),
          country: surveyState.country,
          birthYear: surveyState.birthYear,
          height: isNaN(surveyState.height) ? null : surveyState.height,
          weight: isNaN(surveyState.weight) ? null : surveyState.weight
        })

        this.updateDefault<SurveyState>("SurveyState", surveyState => {
          surveyState.surveyResultId = results.id
        })
      } else {
        await this.surveyActions.updateDocument(update, {
          id: surveyResult.id,
          surveyId: surveyState.surveyId,
          plannedSurveyId: surveyState.plannedSurveyId,
          selectedTags: (surveyState.selectedTags ?? []).join(",")
        })

        // Refresh planned surveys to show any remaining surveys in thank you page
        await this.surveyActions.getView<PlannedSurvey>("PlannedSurveys")
      }
    } catch (err) {
      posthog.capture("sending_survey_results_failed", { error: err })

      this.logger.error("Failed to send survey results", err)
      window.setTimeout(() => alert("Failed to send survey results"), 0)

      return
    } finally {
      this.stopSpinner()
    }

    // Refresh planned surveys to show any remaining surveys in thank you page
    if (surveyState.loginType !== "openLink") await this.surveyActions.getView<PlannedSurvey>("PlannedSurveys")

    this.setState({ shownSurveyPhase: "thankYou", showBlockScreen: false }, () => {
      posthog.capture("$pageview", { $current_url: "/thankYou" })
    })
  }

  private startSpinner() {
    this.updateState(state => (state.showBlockScreen = true))
  }

  private stopSpinner() {
    this.updateState(state => (state.showBlockScreen = false))
  }

  private scrollButtonsIntoView() {
    const surveyState = this.docDefault<SurveyState>("SurveyState")

    setTimeout(() => {
      const buttonContainer = document.getElementById("buttonContainer")
      buttonContainer.scrollIntoView({ block: "end", behavior: "auto" })
    }, 0)
  }

  private triggerLogoutTimer() {
    const surveyState = this.docDefault<SurveyState>("SurveyState")

    if (surveyState.loginType !== "companyCode") return

    clearTimeout(this.logoutTimeout)
    this.logoutTimeout = setTimeout(this.logout, this.appConfig.automaticLogoutMinutes * 1000 * 60)
  }

  private resultAnswerToUpdate = (answer: Answer): AnswerUpdate => ({
    question_id: answer.question_id,
    options: answer.options?.map(({ id, note }) => ({ id, note }))
  })

  private onQuestionAnswerNoneOfTheAbove = (questionId: string) => {
    const { questionsAnsweredWithNoneOfTheAbove } = this.state
    questionsAnsweredWithNoneOfTheAbove[questionId] = true

    this.setState({ questionsAnsweredWithNoneOfTheAbove })
  }

  private onQuestionAnswered = (questionId: string, options: Map<string, string | boolean>) => {
    const { questionsAnsweredWithNoneOfTheAbove } = this.state

    const surveyState = this.docDefault<SurveyState>("SurveyState")

    this.triggerLogoutTimer()

    const result: SurveyResult = this.doc<SurveyResult>(surveyState.surveyResultId, "SurveyResult")
    const survey: SurveyModel = this.doc<SurveyModel>(surveyState.surveyId, "Survey")
    const currentAnswer: SurveyAnswerInstance = result.instances[0]

    const questionIndex = survey.questions.findIndex(q => q.id === questionId)
    if (questionIndex === -1) {
      this.logger.warning("Question not found in survey", { questionId, surveyId: survey.id })
      return
    }

    posthog.capture("question_answered", { questionId, questionIndex })

    const question = survey.questions[questionIndex]
    this.logger.info("Question answered", { title: question.title, options })

    // Remove existing answer for current question
    const answerIndex = currentAnswer.answers.findIndex(a => a.question_id === Number(questionId))
    if (answerIndex > -1) currentAnswer.answers.splice(answerIndex, 1)

    // Add new answer, simple selections' option value is true, open field's is string
    const optionIds = Array.from(options.keys())

    const answered = optionIds.map(id =>
      typeof options.get(id) === "string" ? { id, note: options.get(id) as string } : { id }
    )

    currentAnswer.answers.push({ question_id: Number(questionId), options: answered })

    const answers = currentAnswer.answers.map(this.resultAnswerToUpdate)
    const update: ResultUpdate = {
      __type: "SurveyResult",
      complete: false,
      answers
    }

    this.logger.info("Updating survey results", update)

    // Update the result document
    this.set(result)

    // Send answer to backend
    this.updateQuestionToServer(update, result).then()

    // Make sure the last answered question state is accurate
    const answeredQuestionIndex = this.getLastAnsweredQuestionIndexForPage(this.state.page, answers)
    const lastAnsweredQuestionIndex = this.skipOptionalQuestions(
      survey,
      Math.max(surveyState.lastAnsweredQuestionIndex, questionIndex, answeredQuestionIndex)
    )

    this.updateDefault<SurveyState>(surveyStateType, surveyState => {
      surveyState.lastAnsweredQuestionIndex = lastAnsweredQuestionIndex
    })

    if (questionsAnsweredWithNoneOfTheAbove[question.id]) {
      questionsAnsweredWithNoneOfTheAbove[question.id] = false
      this.setState({ questionsAnsweredWithNoneOfTheAbove })
    }
  }

  private onQuestionOptionSelected = (questionId: string, optionId: string) => {
    this.onQuestionAnswered(questionId, new Map([[optionId, true]]))
  }

  private onQuestionOptionsSelected = (questionId: string, optionIds: string[]) => {
    const options = new Map(optionIds.map(optionId => [optionId, true]))
    this.onQuestionAnswered(questionId, options)
  }

  private async updateQuestionToServer(update: ResultUpdate, result: SurveyResult) {
    const surveyState = this.docDefault<SurveyState>("SurveyState")

    // Open link survey sent to server only in the end
    if (surveyState.loginType === "openLink") return

    if (this.questionUpdatesHalted) {
      this.logger.warning("Not sending question updates due to congestion")
      return
    }

    try {
      this.haltQuestionUpdates()
      await this.surveyActions.updateDocument(update, {
        id: result.id,
        surveyId: surveyState.surveyId,
        plannedSurveyId: surveyState.plannedSurveyId,
        skipLocalUpdates: true
      })
    } finally {
      this.resumeQuestionUpdates()
    }
  }

  private haltQuestionUpdates = () => {
    this.questionUpdatesHalted = true
  }

  private resumeQuestionUpdates = () => {
    this.questionUpdatesHalted = false
  }

  private onPersonalDetailsNext = (results: PersonalDetailsResults) => {
    this.triggerLogoutTimer()

    this.updateDefault<SurveyState>(surveyStateType, surveyState => {
      surveyState.email = results.email
      surveyState.firstName = results.firstName
      surveyState.lastName = results.lastName
      surveyState.selectedTeam = results.selectedTeam
      surveyState.selectedTags = results.selectedTags
      surveyState.country = results.country
      surveyState.birthYear = results.birthYear
      surveyState.height = results.height
      surveyState.weight = results.weight
    })

    const surveyState = this.docDefault<SurveyState>("SurveyState")
    try {
      if (surveyState.loginType === "openLink") {
        // For surveys with identified users validate/update user information
        // (send email to server to check for duplicates)
        this.triggerLogoutTimer()
        if (results.email.length > 0) {
          this.tryEmailValidationCheck(results.email)
          return
        }
      } else {
        // For surveys with identified users validate/update user information
        this.updateUserInformation(surveyState)
      }

      this.setState(
        {
          shownSurveyPhase: "questions"
        },
        () => posthog.capture("$pageview", { $current_url: "/questions" })
      )
    } catch (e) {
      alert("Something went wrong!")
    }
  }

  private async updateUserInformation(surveyState) {
    const user_data: SurveyUserDetails = {
      user_weight: isNaN(surveyState.weight) ? null : surveyState.weight,
      user_height: isNaN(surveyState.height) ? null : surveyState.height,
      user_birth_year: surveyState.birthYear,
      user_country: surveyState.country
    }
    await this.surveyActions.updateUserDetails(user_data)
  }

  private onOpenLinkStart = async () => {
    // we may or may not be logged in (if not logged in, logout will throw an error
    // which we can safely ignore)
    try {
      await this.authenticationActions.logout()
    } catch (e) {
    } finally {
      this.setState({ shownSurveyPhase: "personalDetails" }, () =>
        posthog.capture("$pageview", { $current_url: "/personalDetails" })
      )
    }
  }

  private tryEmailValidationCheck = async (email: string) => {
    // we send the email to the server to check for duplicates
    // if no email found, continue with the survey; if the email already exists
    // the user needs to enter a token value emailed to them to continue
    this.startSpinner()

    try {
      const params = this.getParams()
      const companyCode = params.cc
      const surveyId = params.survey
      const result = await this.surveyActions.validateEmailCheck(email, companyCode, surveyId)
      // if the result contains an email address, this means we've found it in the database
      // and a magic token has already been generated and sent to the user, so ask them to enter it
      if (result.status === 204) {
        // this is an empty result - the email hasn't been found in the database
        this.setState(
          {
            shownSurveyPhase: "questions"
          },
          () => posthog.capture("$pageview", { $current_url: "/questions" })
        )
      } else if (result.status === 200) {
        const response = await result.json().then(data => {
          // we've returned an email that we found in the database, so check this survey hasn't
          // already been completed, then ask the user to enter the token just sent to them
          if (data["status"]?.includes("already_answer")) {
            this.setState(
              {
                shownSurveyPhase: "alreadyCompleted"
              },
              () => posthog.capture("$pageview", { $current_url: "/alreadyCompleted" })
            )
          } else if (data["status"]?.includes("survey_closed")) {
            this.setState(
              {
                shownSurveyPhase: "surveyClosed"
              },
              () => posthog.capture("$pageview", { $current_url: "/surveyClosed" })
            )
          } else if (data["email"]) {
            this.setState(
              {
                shownSurveyPhase: "emailValidate"
              },
              () => posthog.capture("$pageview", { $current_url: "/emailValidate" })
            )
            posthog.capture("email_validation_needed")
          } else {
            // something has gone wrong - we should always either get a token or a
            // response to say the survey has already been completed
            this.setState({ shownSurveyPhase: "error" }, () => posthog.capture("$pageview", { $current_url: "/error" }))
          }
        })
      }
    } catch (e) {
      this.handleError(e)
    } finally {
      this.stopSpinner()
    }
  }

  private retryEmailValidationCheck = () => {
    this.startSpinner()

    // the delay here is so that the user can see their request to retry
    // the email send token thing has actually run (else the screen might
    // not appear to change)
    const emailValidationUiDelay = 1400
    setTimeout(() => {
      const { email } = this.docDefault<SurveyState>("SurveyState")
      this.tryEmailValidationCheck(email)
    }, emailValidationUiDelay)
  }

  private onEmailValidateNext = (emailToken: string) => {
    this.updateDefault<SurveyState>(surveyStateType, surveyState => {
      surveyState.emailToken = emailToken
    })

    // now validate the token the user entered against the magic token in the database
    // (better to do this check here than at the end and have it fail?)
    const surveyState = this.docDefault<SurveyState>("SurveyState")
    this.tryEmailValidate(surveyState.email, emailToken)
  }

  async validateEmailToken(emailAddress: string, tokenValue: string): Promise<Response> {
    const result = await this.authenticationActions.loginWithToken(tokenValue, emailAddress, false)
    return result
  }

  private tryEmailValidate = async (email: string, emailToken: string) => {
    posthog.capture("attempting_email_validation")

    this.startSpinner()
    let showError = true
    let email_error_response = this.txt("email_validation_error") + " " + this.txt("email_check_token")
    try {
      const result = await this.validateEmailToken(email, emailToken)
      if (result.status === 200) {
        // the response from a successful email validation check is an
        // authenticated token that we can use to update the user's information.
        // The loginWithToken function will already have set the access_token
        // (a failed check returns an error and no access_token value)
        if (result) {
          this.setState(
            {
              shownSurveyPhase: "questions"
            },
            () => posthog.capture("$pageview", { $current_url: "/questions" })
          )
          showError = false
        }
      }
    } catch (e) {
      console.log(e)
      if (e.httpStatusCode === 400 || e.error === "invalid_grant") {
        this.setState({
          emailValidError: this.txt("email_token_invalid") + " " + this.txt("email_check_token")
        })
        showError = false
      }

      posthog.capture("email_validation_failed", { error: e })
    } finally {
      this.stopSpinner()
    }

    if (showError) {
      alert(email_error_response)
    }
  }

  private onTokenChanged = () => {
    this.setState({
      emailValidError: ""
    })
  }

  private onPreviousQuestionPage = () => {
    this.triggerLogoutTimer()

    this.scrollButtonsIntoView()

    if (this.state.page === 0 && this.arePersonalDetailsShown()) {
      posthog.capture("previous_page", { page: "personalDetails" })

      this.setState({
        shownSurveyPhase: "personalDetails"
      })
    } else {
      posthog.capture("previous_page", { page: `page: ${this.state.page! - 1}` })

      this.setState(prevState => ({ page: prevState.page - 1 }))
    }
  }

  private onNextQuestionPage = () => {
    this.triggerLogoutTimer()

    posthog.capture("next_page", { page: `page: ${this.state.page! + 1}` })

    this.updateState(state => {
      const surveyState = this.docDefault<SurveyState>("SurveyState")

      state.page++

      const result = this.doc<SurveyResult>(surveyState.surveyResultId, "SurveyResult")
      const currentAnswer: SurveyAnswerInstance = result.instances[0]
      if (result) {
        const answeredQuestionIndex = this.getLastAnsweredQuestionIndexForPage(state.page, currentAnswer?.answers ?? [])
        this.set(
          Object.assign({}, surveyState, {
            lastAnsweredIndex: Math.max(surveyState.lastAnsweredQuestionIndex, answeredQuestionIndex)
          })
        )
      }

      if (state.page > state.lastPageVisited) {
        window.scrollTo(0, 0)
        state.lastPageVisited = state.page
      } else if (state.lastPageVisited > state.lastPageVisited + 1) {
        this.scrollButtonsIntoView()
      }
    })
  }

  private skipOptionalQuestions(survey: SurveyModel, questionIndex: number) {
    const { questionsAnsweredWithNoneOfTheAbove } = this.state

    for (; questionIndex < survey.questions.length - 1; questionIndex++) {
      const question = survey.questions[questionIndex + 1]

      if (
        question?.min_required_options !== 0 ||
        (question.kind === "checkbox" && !questionsAnsweredWithNoneOfTheAbove[question.id])
      )
        break
    }

    return questionIndex
  }
}
