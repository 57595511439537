import Divider from "@material-ui/core/Divider"
import { WithStyles, createStyles, withStyles } from "@material-ui/core/styles"
import * as csx from "csx"

import { Answer, Question, Survey, SurveyAnswerInstance, SurveyResult } from "app/surveys/modules/state/model/Model"
import { ViewComponentProps } from "core/components/base/ViewComponent"
import { isSafari } from "lib/ui/compatibility/Browsers"
import Button from "lib/ui/components/form/Button"
import CardLayout from "lib/ui/components/layout/CardLayout"
import LanguageSelector from "lib/ui/components/settings/LanguageSelector"
import Typography from "lib/ui/components/typography/Typography"

import { ViewComponent } from "../../../base/ViewComponent"
import CheckboxQuestion from "../../../ui/questions/CheckboxQuestion"
import FieldQuestion from "../../../ui/questions/FieldQuestion"
import NumberQuestion from "../../../ui/questions/NumberQuestion"
import OrderedDropdownQuestion from "../../../ui/questions/OrderedDropdownQuestion"
import RadioQuestion from "../../../ui/questions/RadioQuestion"
import QuestionsProgress from "./QuestionsProgress"

const styles = () =>
  createStyles({
    surveyTitleDesktop: {
      fontSize: "16px",
      color: ViewComponent.theme.colors.baseGray.toString()
    },
    surveyTitleMobile: {
      fontSize: "28px"
    },
    surveyQuestionIndexMobile: {
      fontSize: "14px",
      color: ViewComponent.theme.colors.baseGray.toString()
    },
    progress: {
      fontSize: "16px",
      width: "6em",
      marginTop: "1em",
      textAlign: "right"
    },
    questionIndex: {
      color: ViewComponent.theme.colors.secondary.toString(),
      paddingRight: "0.5rem",
      fontWeight: "bold"
    },
    hr: {
      marginBottom: "1rem",
      marginTop: "1rem"
    },
    categoryIcon: {
      height: "2rem",
      alignSelf: "center",
      width: "2rem",
      marginRight: "1rem",
      opacity: 0.5
    },
    categoryTitle: {
      fontWeight: "bold",
      display: "flex"
    },
    categoryTitleMobile: {
      fontWeight: "bold",
      display: "flex",
      fontSize: "32px"
    },
    categoryDescription: {
      marginBottom: "2rem"
    },
    languageSelector: {
      justifyContent: "flex-end"
    },
    cardContent: {
      padding: "0 2rem 2rem 2rem",
      display: "flex",
      flexDirection: "column"
    },
    multiQuestionList: {
      "& > li::marker": {
        color: ViewComponent.theme.colors.secondary.toString(),
        fontSize: "20px",
        fontWeight: "bold"
      }
    },
    singleQuestionList: {
      listStyle: "none",
      paddingLeft: 0
    },
    title: {
      marginTop: 0,
      fontSize: "20px"
    },
    hFirstQuestion: {
      marginBottom: "0.75em",
      marginTop: "1em",
      fontSize: "18px"
    },
    p: {
      marginBottom: 0
    },
    col: {
      marginLeft: "1em",
      marginRight: "1em"
    },
    disabled: {
      color: csx.important(ViewComponent.theme.colors.disabled.toString()),
      "&::marker": {
        color: csx.important(ViewComponent.theme.colors.disabled.toString())
      }
    },
    contentRow: {
      margin: "0.2em 1em"
    },
    questionColEnps: {
      paddingTop: "2rem",
      paddingBottom: "2rem"
    },
    questionsContentRow: {
      margin: "1rem 0 2rem 0"
    },
    buttonContainerDesktop: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      // "invisible" padding to improve auto scroll behavior
      paddingBottom: "1rem",
      marginBottom: "-1rem"
    },
    buttonContainerMobile: {
      display: "flex",
      justifyContent: "space-between",
      flexGrow: 1,
      paddingBottom: "1rem",
      alignItems: "flex-end"
    },
    topContainerOuter: {
      margin: "2.5rem 2.5rem 0 2.5rem"
    },
    topContainerDesktop: {
      display: "flex",
      justifyContent: "space-between"
    },
    topContainerMobile: {
      justifyContent: "space-between",
      minHeight: "4em",
      display: "flex"
    },
    topContainerColumn: {
      display: "flex",
      flexDirection: "column"
    },
    logo: {
      width: "7rem"
    },
    mobileLayout: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      margin: "1.5rem",
      flexGrow: 1
    },
    radioQuestion: {
      marginLeft: "1rem"
    }
  })

function isInViewport(element) {
  // function basically returns true if both the top and bottom of
  // a given element are inside the viewport/window, or false otherwise.
  // (uses the bounding box of the entire element)

  if (!element) return false
  const top = element.getBoundingClientRect().top
  const bottom = element.getBoundingClientRect().bottom
  return top >= 0 && bottom <= window.innerHeight
}

interface QuestionsPageViewProps extends ViewComponentProps {
  maxQuestions: number
  lastAnsweredQuestionIndex: number
  firstQuestionToShow: number
  lastQuestionToShow: number
  survey: Survey
  surveyResult: SurveyResult
  page: number
  maxPage: number
  language: string
  background?: string
  icon: string | undefined
  logo: string
  allowPreviousOnFirstPage: boolean
  onPrevious: () => void
  onNext: () => void
  onDone: () => void
  onSetLanguage: (language: string) => void
  onAnswer: (questionId: string, answers: Map<string, string | boolean>) => void
  onQuestionAnswerNoneOfTheAbove: (questionId: string) => void
  onOptionSelected: (questionId: string, optionId: string) => void
  onOptionsSelected: (questionId: string, optionIds: string[]) => void
  onLogout: () => void
}

export class QuestionsPageView extends ViewComponent<QuestionsPageViewProps & WithStyles<typeof styles>> {
  get componentName() {
    return ["survey", "pages", "questions", "QuestionsPageView"]
  }

  private lastSeenAnsweredQuestionIndex?: number

  componentDidMount() {
    this.scrollNextQuestionIntoView()
  }

  componentDidUpdate() {
    this.scrollNextQuestionIntoView()
  }

  get firstQuestion() {
    const { firstQuestionToShow, survey } = this.props

    return survey.questions[firstQuestionToShow]
  }

  get category() {
    return !!this.firstQuestion?.category?.en
  }

  render() {
    super.render()

    return this.isMobile ? this.renderMobile() : this.renderDesktop()
  }

  private scrollNextQuestionIntoView() {
    const { lastAnsweredQuestionIndex } = this.props

    if (isSafari()) return

    if (this.lastSeenAnsweredQuestionIndex !== lastAnsweredQuestionIndex) {
      const lastAnsweredQuestionKind = this.props.survey.questions[lastAnsweredQuestionIndex]?.kind
      const currentQuestionLi = document.getElementById(`question_li_${lastAnsweredQuestionIndex}`)
      const nextQuestionLi = document.getElementById(`question_li_${lastAnsweredQuestionIndex + 1}`)
      const buttonContainer = document.getElementById("buttonContainer")

      if (!nextQuestionLi) {
        // New question was answered and next question is not found, scroll buttons into view
        buttonContainer.scrollIntoView({ block: "end", behavior: "smooth" })
      } else if (["radio", "dropdown"].includes(lastAnsweredQuestionKind)) {
        // Scroll next question into view when current question only
        // needs a single click answer

        // in a PC browser, scrollIntoView always puts the document element at the bottom of the page
        // and this can give the appearance of the page "jumping around" (if, for example, the next element
        // is already visible in it's entirety in the middle, or at the top of the page).
        // So only scrollIntoView if part of the element isn't in the current viewport?

        if (!isInViewport(nextQuestionLi)) {
          nextQuestionLi.scrollIntoView({ block: "end", behavior: "smooth" })
        }
      } else if (["field", "checkbox"].includes(lastAnsweredQuestionKind) && currentQuestionLi) {
        // Scroll next question into view when current question only
        // needs a single click answer

        // as above, only scroll into view if the document element isn't already fully in the viewport
        if (!isInViewport(nextQuestionLi)) {
          currentQuestionLi.scrollIntoView({ block: "end", behavior: "smooth" })
        }
      }
    }

    this.lastSeenAnsweredQuestionIndex = lastAnsweredQuestionIndex
  }

  private renderMobile() {
    const { classes } = this.props

    return (
      <div className={classes.mobileLayout}>
        {this.renderHeaderMobile()}
        {this.renderMain()}
      </div>
    )
  }

  private renderDesktop() {
    const { background, classes } = this.props

    return (
      <CardLayout
        maxWidth="50rem"
        width="100%"
        header={this.renderHeaderDesktop()}
        background={this.theme.background}
        headerBackgroundColor={this.theme.colors.windowColor}
      >
        <div className={classes.cardContent}>{this.renderMain()}</div>
      </CardLayout>
    )
  }

  private renderHeaderDesktop() {
    const { classes, language, onSetLanguage, survey, page, maxPage } = this.props

    return (
      <div className={classes.topContainerOuter}>
        <div className={classes.topContainerDesktop}>
          <div>
            {this.category && this.renderProgress()}
            {this.category ? this.renderCategory() : this.renderTitle()}
          </div>
          <div>
            {this.renderLogo()}

            <LanguageSelector
              className={classes.languageSelector}
              id="language-selector"
              language={language}
              setLanguage={onSetLanguage}
            />
          </div>
        </div>

        <Divider className={classes.hr} />

        {this.renderSubtitle()}
      </div>
    )
  }

  private renderProgress() {
    const { survey, page, maxPage } = this.props

    return (
      <Typography>
        {this.txt(survey.title)} &bull; {page + 1}/{maxPage + 1}
      </Typography>
    )
  }

  private renderHeaderMobile() {
    const { classes, language, onSetLanguage, survey, page, maxPage } = this.props

    return (
      <div>
        <div className={classes.topContainerMobile}>
          {this.renderLogo()}

          <LanguageSelector
            className={classes.languageSelector}
            id="language-selector"
            language={language}
            setLanguage={onSetLanguage}
          />
        </div>
        {this.category && this.renderProgress()}
        {this.category ? this.renderCategory() : this.renderTitle()}

        <Divider className={classes.hr} />

        {this.renderSubtitle()}
      </div>
    )
  }

  private renderMain() {
    super.render()

    const { classes, firstQuestionToShow } = this.props

    return (
      <>
        <ol className={classes.multiQuestionList} start={firstQuestionToShow + 1}>
          {this.renderQuestions()}
        </ol>
        {this.renderCommandButtons()}
      </>
    )
  }

  private renderCategory() {
    const { classes, icon } = this.props

    return (
      <Typography className={this.isMobile ? classes.categoryTitleMobile : classes.categoryTitle} variant="heading">
        {icon && <img alt="" className={classes.categoryIcon} src={icon} />}
        {this.txt(this.firstQuestion.category)}
      </Typography>
    )
  }

  private renderTitle() {
    const { classes, survey } = this.props

    return (
      <Typography className={this.isMobile ? classes.categoryTitleMobile : classes.categoryTitle} variant="heading">
        {this.txt(survey.title)}
      </Typography>
    )
  }

  private renderSubtitle() {
    const { classes } = this.props

    if (!this.firstQuestion?.category_description?.en) return

    return (
      <Typography className={classes.categoryDescription} paragraph variant="description">
        {this.txt(this.firstQuestion.category_description)}
      </Typography>
    )
  }

  private renderQuestions() {
    const { firstQuestionToShow, lastAnsweredQuestionIndex, lastQuestionToShow, survey, surveyResult } = this.props

    if (!surveyResult?.instances) {
      return false
    }
    const currentAnswer: SurveyAnswerInstance = surveyResult?.instances[0]

    if (!currentAnswer?.answers) return false

    const renderedQuestions = []

    for (let i = firstQuestionToShow; i <= lastQuestionToShow; i++) {
      const question = survey.questions[i]
      if (!question) continue

      const answer = currentAnswer.answers.find(a => a.question_id === Number(question.id))

      renderedQuestions.push(this.renderQuestion(question, i, i > lastAnsweredQuestionIndex + 1, answer))
    }

    return renderedQuestions
  }

  private renderQuestion(question: Question, index: number, disabled: boolean, answer?: Answer) {
    switch (question.kind) {
      case "boolean":
      case "radio":
      case "dropdown":
      case "gas":
        return this.renderRadioQuestion(question, index, disabled, answer)
      case "checkbox":
        return this.renderCheckboxQuestion(question, index, disabled, answer)
      case "field":
        return this.renderFieldQuestion(question, index, disabled, answer)
      case "ordereddropdown":
        return this.renderOrderedDropdownQuestion(question, index, disabled, answer)
    }
  }

  private renderCheckboxQuestion(question: Question, index: number, disabled: boolean, answer?: Answer) {
    const { classes } = this.props

    return (
      <li className={disabled ? classes.disabled : undefined} key={question.id} id={`question_li_${index}`}>
        {this.renderQuestionTitle(question, disabled)}
        <div className={classes.questionsContentRow}>
          <CheckboxQuestion
            question={question}
            answer={answer}
            onOptionsSelected={this.props.onOptionsSelected}
            onQuestionAnswerNoneOfTheAbove={this.props.onQuestionAnswerNoneOfTheAbove}
          />
        </div>
      </li>
    )
  }

  private renderFieldQuestion(question: Question, index: number, disabled: boolean, answer?: Answer) {
    const { classes } = this.props

    return (
      <li key={question.id} id={`question_li_${index}`}>
        {this.renderQuestionTitle(question, disabled)}
        <div className={classes.questionsContentRow}>
          <FieldQuestion question={question} answer={answer} onAnswer={this.props.onAnswer} />
        </div>
      </li>
    )
  }

  private renderLogo() {
    const { logo, classes } = this.props

    return <img alt="Hintsa" className={classes.logo} src={logo} />
  }

  private renderOrderedDropdownQuestion(question: Question, index: number, disabled: boolean, answer?: Answer) {
    const { classes } = this.props

    return (
      <li key={question.id} id={`question_li_${index}`}>
        {this.renderQuestionTitle(question, disabled)}
        <div className={classes.questionsContentRow}>
          <OrderedDropdownQuestion
            disabled={disabled}
            question={question}
            answer={answer}
            onOptionsSelected={this.props.onOptionsSelected}
          />
        </div>
      </li>
    )
  }

  private renderRadioQuestion(question: Question, index: number, disabled: boolean, answer?: Answer) {
    const { classes, onOptionSelected } = this.props

    const showAsnumber = this.showAsNumberQuestion(question)

    return (
      <li key={question.id} id={`question_li_${index}`} className={disabled ? classes.disabled : undefined}>
        {this.renderQuestionTitle(question, disabled)}
        <div className={classes.questionsContentRow}>
          <div className={showAsnumber ? classes.questionColEnps : undefined}>
            {this.showAsNumberQuestion(question) ? (
              <NumberQuestion
                disabled={disabled}
                question={question}
                answer={answer}
                labelStart={question.key === "enps" ? this.txt("not_likely") : undefined}
                labelEnd={question.key === "enps" ? this.txt("very_likely") : undefined}
                onOptionSelected={onOptionSelected}
              />
            ) : (
              <RadioQuestion
                className={classes.radioQuestion}
                disabled={disabled}
                question={question}
                answer={answer}
                onOptionSelected={onOptionSelected}
              />
            )}
          </div>
        </div>
      </li>
    )
  }

  private renderQuestionTitle(question: Question, disabled: boolean) {
    const { classes } = this.props

    return (
      <div>
        <Typography className={disabled ? classes.disabled : undefined} variant="body">
          {this.txt(question.title)}
        </Typography>
        {question.description?.en && <p>{this.txt(question.description)}</p>}
      </div>
    )
  }

  private renderCommandButtons() {
    const {
      classes,
      firstQuestionToShow,
      lastAnsweredQuestionIndex,
      lastQuestionToShow,
      maxQuestions,
      onDone,
      onNext,
      onPrevious,
      page,
      maxPage,
      allowPreviousOnFirstPage
    } = this.props

    const lastVisible = lastQuestionToShow === maxQuestions - 1
    const previousDisabled = firstQuestionToShow === 0 && !allowPreviousOnFirstPage
    const nextDisabled = lastAnsweredQuestionIndex < lastQuestionToShow

    return (
      <div
        id="buttonContainer"
        className={this.isMobile ? classes.buttonContainerMobile : classes.buttonContainerDesktop}
      >
        <Button disabled={previousDisabled} onClick={onPrevious}>
          {this.txt("core", "components", "ui", "form", "previous")}
        </Button>

        {!this.isMobile && maxPage > 0 && <QuestionsProgress progress={page + 1} maxItems={maxPage + 1} />}

        {lastVisible ? (
          <Button disabled={nextDisabled} onClick={onDone}>
            {this.txt("done")}
          </Button>
        ) : (
          <Button disabled={nextDisabled} onClick={onNext}>
            {this.txt("core", "components", "ui", "form", "next")}
          </Button>
        )}
      </div>
    )
  }

  private showAsNumberQuestion(question: Question) {
    if (question.key === "enps") return true

    return question.options.filter(option => (~~option.title.en).toString() !== option.title.en).length === 0
  }
}

export default withStyles<any, any, QuestionsPageViewProps>(styles)(QuestionsPageView)
