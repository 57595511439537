import { ViewComponentProps, ViewComponentState } from "core/components/base/ViewComponent"
import Dropdown, { DropdownOption } from "lib/ui/components/form/Dropdown"

import { QuestionOption } from "../../../../surveys/modules/state/model/Model"
import { ViewComponent } from "../../base/ViewComponent"

interface OrderedDropdownQuestionViewProps extends ViewComponentProps {
  options: QuestionOption[]
  selected: string[]
  classes: any
  minOptions: number
  maxOptions: number
  disabled?: boolean
  onChange: (optionIds: string[]) => void
}

interface OrderedDropdownQuestionViewState extends ViewComponentState {
  selections: string[]
}

class OrderedDropdownQuestionView extends ViewComponent<
  OrderedDropdownQuestionViewProps,
  OrderedDropdownQuestionViewState
> {
  constructor(props) {
    super(props)

    this.state = { selections: props.selected ?? [] }
  }

  get componentName() {
    return ["survey", "ui", "questions", "OrderedDropdownQuestionView"]
  }

  render() {
    const { disabled, maxOptions, minOptions } = this.props
    super.render()

    const dropDowns = []
    for (let i = 0; i < maxOptions; i++) {
      const value = this.state.selections[i]

      dropDowns.push(
        <li key={i.toString()}>
          <Dropdown
            options={this.getOptions(value)}
            // Allow empty when this is the last selection and never allow to unselect the first option
            allowEmptySelection={!this.state.selections[i + 1] && this.state.selections[i] && i !== 0}
            // Allow slecting only the next selection
            disabled={disabled || (!this.state.selections[0] && i > 0) || (!this.state.selections[i - 1] && i > 1)}
            value={value}
            required={i <= minOptions}
            fullWidth={true}
            onChange={selection => this.setSelection(i, selection)}
          />
        </li>
      )
    }

    return <ol>{dropDowns}</ol>
  }

  private setSelection = (index: number, value: string | null) => {
    this.updateState(state => (state.selections[index] = value), this.updateParentWithSelectedOptions)
  }

  private updateParentWithSelectedOptions = () => {
    const options = this.state.selections.filter(option => typeof option === "string")

    if (options.length >= this.props.minOptions) {
      this.props.onChange(options)
    }
  }

  private getOptions(selectedValue: string): DropdownOption[] {
    const { options } = this.props
    const { selections } = this.state

    return options
      .map(option => ({ label: this.txt(option.title), value: option.id.toString() }))
      .filter(option => option.value === selectedValue || selections.indexOf(option.value) === -1)
  }
}

export default OrderedDropdownQuestionView as any
