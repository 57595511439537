import classNames from "classnames"
import { ViewComponentProps } from "core/components/base/ViewComponent"
import * as csx from "csx"
import Button from "lib/ui/components/form/Button"
import Grid from "lib/ui/components/layout/grid/Grid"
import Markdown from "lib/ui/components/markdown/Markdown"
import Typography from "lib/ui/components/typography/Typography"
import { stylesheet } from "typestyle"
import { ViewComponent } from "../../../../../base/ViewComponent"
import { reportBodyClass, reportLargeBodyClass } from "../../WellbeingReport"

interface Props extends ViewComponentProps {}

export default class CustomAreaMasterClass extends ViewComponent<Props> {
  get componentName() {
    return ["report", "pages", "report", "CustomAreaMasterClass"]
  }

  constructor(props) {
    super(props)

    setupStyles()
  }

  render() {
    return (
      <>
        <Grid spacer spacerSize={3} />
        <Grid sm={12} className={styles.container}>
          <Typography useContrastColor className={classNames(reportLargeBodyClass, styles.headingTop)}>
            {this.txt("heading1")}
          </Typography>
          <Typography useContrastColor className={classNames(reportLargeBodyClass, styles.headingBottom)}>
            {this.txt("heading2")}
          </Typography>
          <Typography useContrastColor component="div" className={reportBodyClass}>
            <Markdown content={this.txt("content")} />
          </Typography>
          <Button variant="secondary" className={styles.button} onClick={this.openMasterClass}>
            {this.txt("action_button")}
          </Button>
        </Grid>
      </>
    )
  }

  private openMasterClass = () => {
    window.open("https://academy.hintsa.com/courses/-master-performance-modes", "_blank")
  }
}

let styles

const setupStyles = () => {
  styles = stylesheet({
    headingTop: {
      marginTop: "0rem",
      marginBottom: "0.5rem",
      fontWeight: "bold"
    },

    headingBottom: {
      marginTop: "0rem"
    },
    container: {
      padding: "1rem 2rem 3rem 2rem"
    },
    button: {
      marginTop: csx.important("3rem"),
      color: csx.important(CustomAreaMasterClass.theme.colors.fontLightColor.toString()),
      background: csx.important("transparent"),
      borderColor: csx.important(CustomAreaMasterClass.theme.colors.fontLightColor.toString()),
      borderWidth: csx.important("3px"),
      height: csx.important("3rem"),
      paddingLeft: csx.important("2rem"),
      paddingRight: csx.important("2rem")
    }
  })
}
