import { SurveyActionsModule } from "app/surveys/modules/actions/SurveyActions"
import { SurveysApiModule } from "app/surveys/modules/api/SurveysApi"
import { ConversionMapModule } from "app/surveys/modules/state/conversionmap/ConversionMap"
import { localizationConfig } from "app/surveys_app/modules/config/localizationConfig"
import { BaseComponent } from "core/components/base/BaseComponent"
import { ModuleManager } from "core/controller/ModuleManager"
import { CoreActionsModule } from "core/modules/actions/CoreActions"
import { CoreApiModule } from "core/modules/api/CoreApi"
import { coreTranslations } from "core/modules/localization/CoreTranslations"
import { I18n } from "core/modules/localization/I18n"
import { LocalizationModule } from "core/modules/localization/Localization"
import { ProgressManagerModule } from "core/modules/progress/ProgressManager"
import { ConversionMapModule as CoreConversionMapModule } from "core/modules/state/conversionmap/ConversionMap"
import { DocumentConverterModule } from "core/modules/state/documentconverter/DocumentConverter"
import { InitialStateModule as CoreInitialStateModule } from "core/modules/state/initialstate/InitialState"
import { ModelManagerModule } from "core/modules/state/model/ModelManager"
import { CoreReducerModule } from "core/modules/state/reducers/CoreReducer"
import { StoreModule } from "core/modules/store/Store"
import { HintsaThemeConstants } from "core/modules/themes/HintsaThemeConstants"
import { AuthenticationActionsModule } from "lib/authentication/modules/actions/AuthenticationActions"
import { OauthApiModule } from "lib/authentication/modules/api/OauthApi"
import { ConversionMapModule as AuthenticationConversionMapModule } from "lib/authentication/modules/state/conversionmap/ConversionMap"
import { InitialStateModule as AuthenticationInitialStateModule } from "lib/authentication/modules/state/initialstate/InitialState"
import { uiTranslations } from "lib/ui/modules/localization/Translations"
import { surveysAppTranslations } from "./modules/localization/AppTranslations"
import { ThemeModule } from "./modules/themes/AppTheme"

/**
 * Register all modules used by the application
 * @param moduleManager Application's module manager
 */
export function setupModules(moduleManager: ModuleManager) {
  // Set theme to use
  BaseComponent.theme = new ThemeModule()

  // I18n
  I18n.setup(localizationConfig)
  I18n.addTranslations(coreTranslations)
  I18n.addTranslations(uiTranslations)
  I18n.addTranslations(surveysAppTranslations)

  // Core
  moduleManager.registerModule(new StoreModule())
  moduleManager.registerModule(new CoreActionsModule())
  moduleManager.registerModule(new ModelManagerModule())
  moduleManager.registerModule(new CoreApiModule())
  moduleManager.registerModule(new DocumentConverterModule())
  moduleManager.registerModule(new CoreInitialStateModule(), "InitialState")
  moduleManager.registerModule(new CoreConversionMapModule())
  moduleManager.registerModule(new LocalizationModule())
  moduleManager.registerModule(new CoreReducerModule(), "Reducer")
  moduleManager.registerModule(new ProgressManagerModule())
  moduleManager.registerModule(BaseComponent.theme as any)

  // Theme
  moduleManager.registerModule(new HintsaThemeConstants(), "ThemeConstants")

  // Authentication
  moduleManager.registerModule(new OauthApiModule())
  moduleManager.registerModule(new AuthenticationActionsModule())
  moduleManager.registerModule(new AuthenticationInitialStateModule(), "InitialState")
  moduleManager.registerModule(new AuthenticationConversionMapModule(), "ConversionMapSource")

  // Surveys
  moduleManager.registerModule(new ConversionMapModule(), "ConversionMapSource")
  moduleManager.registerModule(new SurveysApiModule())
  moduleManager.registerModule(new SurveyActionsModule())

  moduleManager.setup()
}
