import { ApiDescription, ConversionMap, ConversionMapRoot } from "core/modules/state/conversionmap/ConversionMap"
import {
  DateTimeValueConverter,
  DateValueConverter,
  LinkArrayConverter,
  LinkConverter
} from "core/modules/state/conversionmap/ValueConverters"

import { BaseModule } from "../../../controller/Module"
import { QuestionCategoryConverter } from "./QuestionCategoryConverter"

export class ConversionMapModule extends BaseModule implements ConversionMap {
  get moduleName() {
    return "ConversionMap"
  }

  get map(): ConversionMapRoot {
    return conversionMapContents
  }
}

/**
 * Describes how objects are translated between internal JS format API JSON
 */
const conversionMapContents: any = {
  OpenLinkSurvey: {
    date: new DateValueConverter(),
    survey: new LinkConverter("Survey"),
    organisation_survey: new LinkConverter("OrganisationSurvey", {
      importType: "inline",
      exportType: "none"
    }),
    __api: <ApiDescription>{
      get: {
        segments: ["open_link_surveys", { name: "id" }],
        search: [
          { name: "client_id", required: true },
          { name: "client_secret", required: true },
          { name: "company_code", paramName: "companyCode", required: true }
        ]
      }
    }
  },
  Answer: {
    options: new LinkArrayConverter("QuestionOption")
  },
  Consent: {
    created_at: new DateTimeValueConverter(),
    __api: <ApiDescription>{
      create: {
        segments: ["me", "consents"],
        search: [{ name: "access_token" }]
      }
    }
  },
  Invite: {
    user: new LinkConverter("User", { isCompactDocument: true })
  },
  Item: {
    entry: new LinkConverter("Unknown", { typeKey: "kind" })
  },
  Media: {
    created_at: new DateTimeValueConverter()
  },
  Organisation: {},
  PlannedSurvey: {
    date: new DateValueConverter(),
    survey: new LinkConverter("Survey"),
    organisation_survey: new LinkConverter("OrganisationSurvey", {
      importType: "inline",
      exportType: "none"
    }),
    __api: <ApiDescription>{
      get: {
        segments: ["planned_surveys", { name: "id" }],
        search: [{ name: "access_token" }]
      },
      remove: {
        segments: ["planned_surveys", { name: "id" }],
        search: [{ name: "access_token" }]
      }
    }
  },
  OrganisationSurvey: {
    ends_on: new DateValueConverter(),
    started_at: new DateValueConverter()
  },
  Question: {
    category: new QuestionCategoryConverter(),
    options: new LinkArrayConverter("QuestionOption", {
      conversion: "inline"
    })
  },
  QuestionOption: {},
  RequestAction: {},
  Survey: {
    questions: new LinkArrayConverter("Question", { conversion: "inline" }),
    __api: <ApiDescription>{
      get: {
        segments: ["surveys", { name: "id" }],
        search: [{ name: "access_token" }]
      },
      create: {
        segments: ["surveys"],
        search: [{ name: "access_token" }]
      },
      update: {
        segments: ["surveys", { name: "id" }],
        search: [
          { name: "access_token" },
          {
            name: "survey_id",
            paramName: "surveyId",
            required: true
          },
          {
            name: "planned_survey_id",
            paramName: "plannedSurveyId",
            required: true
          },
          {
            name: "selected_tags",
            paramName: "selectedTags",
            required: true
          }
        ]
      }
    }
  },
  SurveyResult: {
    date: new DateValueConverter(),
    user: new LinkConverter("User", { isCompactDocument: true }),
    answer: new LinkArrayConverter("Answer", { conversion: "inline" }),
    __api: <ApiDescription>{
      get: {
        segments: ["survey_results", { name: "id" }],
        search: [{ name: "access_token" }, { name: "client_id" }, { name: "client_secret" }]
      },
      create: {
        segments: ["survey_results"],
        search: [
          { name: "access_token" },
          { name: "open_link_survey_id", paramName: "openLinkSurveyId" },
          { name: "client_id" },
          { name: "client_secret" },
          { name: "company_code", paramName: "companyCode" },
          { name: "planned_survey_id", paramName: "plannedSurveyId" },
          { name: "survey_id", paramName: "surveyId", required: true },
          { name: "email", paramName: "email" },
          { name: "selected_team", paramName: "selectedTeam" },
          { name: "selected_tags", paramName: "selectedTags" },
          { name: "first_name", paramName: "firstName" },
          { name: "last_name", paramName: "lastName" },
          { name: "country", paramName: "country" },
          { name: "birth_year", paramName: "birthYear" },
          { name: "height", paramName: "height" },
          { name: "weight", paramName: "weight" }
        ]
      },
      update: {
        segments: ["survey_results", { name: "id" }],
        search: [
          { name: "access_token" },
          { name: "planned_survey_id", paramName: "plannedSurveyId", required: true },
          { name: "selected_tags", paramName: "selectedTags" },
          { name: "survey_id", paramName: "surveyId", required: true }
        ]
      }
    }
  },
  SurveyResultsExtra: {
    __api: <ApiDescription>{
      documentType: "SurveyResultsExtra",
      update: {
        segments: ["survey_results", { name: "reportId" }, "extra"],
        search: [{ name: "id", paramName: "answerId" }, { name: "client_id" }, { name: "client_secret" }]
      }
    }
  },
  Tag: {},
  User: {
    created_at: new DateTimeValueConverter(),
    birthday: new DateTimeValueConverter()
  },
  UserUpdate: {
    __api: <ApiDescription>{
      documentType: "UserUpdate",
      update: {
        segments: ["me", "edit_user"],
        search: [{ name: "access_token" }]
      }
    }
  },
  EmailVerify: {
    __api: <ApiDescription>{
      documentType: "EmailVerify",
      update: {
        segments: ["emails", "verify"],
        search: [{ name: "access_token" }]
      }
    }
  },
  EmailValidateCheck: {
    __api: <ApiDescription>{
      documentType: "EmailValidateCheck",
      get: {
        segments: ["open_link_surveys", "check_email"],
        search: [
          { name: "email" },
          { name: "client_id" },
          { name: "client_secret" },
          { name: "access_token" },
          { name: "company_code" },
          { name: "survey_id" },
          { name: "id" }
        ]
      }
    }
  },
  EmailValidateToken: {
    __api: <ApiDescription>{
      documentType: "EmailValidateToken",
      apiRelativePath: ["oauth"],
      create: {
        segments: ["open_link_surveys", "verify_email"],
        search: [
          { name: "email" },
          { name: "magic_token" },
          { name: "client_id" },
          { name: "client_secret" },
          { name: "grant_type" },
          { name: "access_token" }
        ]
      }
    }
  },

  // Views

  Consents: {
    __api: <ApiDescription>{
      documentType: "Consent",
      get: {
        segments: ["me", "consents"],
        search: [{ name: "access_token" }, { name: "scope" }]
      }
    }
  },
  PlannedSurveys: {
    __api: <ApiDescription>{
      documentType: "PlannedSurvey",
      get: {
        segments: ["planned_surveys"],
        search: [{ name: "access_token" }, { name: "page", default: 1 }, { name: "per_page", default: 50 }]
      }
    }
  },
  SurveyResults: {
    __api: <ApiDescription>{
      documentType: "SurveyResult",
      get: {
        segments: ["survey_results"],
        search: [
          { name: "access_token" },
          { name: "planned_survey_id", paramName: "plannedSurveyId" },
          { name: "incomplete", paramName: "incomplete" }
        ]
      }
    }
  }
}
