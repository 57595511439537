import classNames from "classnames"
import { stylesheet } from "typestyle"

import { ViewComponent } from "app/surveys_app/components/base/ViewComponent"
import { DownloadPdfButton } from "app/surveys_app/components/ui/button/DownloadPdfButton"
import { ViewComponentProps } from "core/components/base/ViewComponent"
import Grid from "lib/ui/components/layout/grid/Grid"
import Typography from "lib/ui/components/typography/Typography"
import { ReportVariant, reportBodyClass, reportSmallHeadingClass } from "../../WellbeingReport"

interface Props extends ViewComponentProps {
  pdfName?: string
  pdfUrl: string
  reportVariant: ReportVariant
}

export default class ReportSectionFooter extends ViewComponent<Props> {
  get componentName() {
    return ["report", "pages", "report", "ReportSectionFooter"]
  }

  constructor(props: Props) {
    super(props)

    setupStyles()
  }

  render() {
    const { pdfName, pdfUrl, reportVariant } = this.props

    return (
      <>
        {reportVariant === "pdf" && <div className="hard-page-break" />}
        <Grid container>
          <Grid spacer spacerSize={reportVariant === "mobile" ? 2 : 6} />
          <Grid xs={12} md={3}></Grid>
          <Grid xs={12} md={6}>
            <Typography useContrastColor className={classNames(reportSmallHeadingClass, styles.heading1)}>
              {this.txt("heading1")}
            </Typography>
            <Typography useContrastColor className={classNames(reportSmallHeadingClass, styles.heading2)}>
              {this.txt("heading2")}
            </Typography>
            <Typography useContrastColor className={classNames(reportBodyClass, styles.content)}>
              {this.txt("content1")}
            </Typography>
            <Typography useContrastColor className={classNames(reportBodyClass, styles.content)}>
              {this.txt("content2")}
            </Typography>
            <div className={styles.centered}>
              <DownloadPdfButton className={styles.button} filename={pdfName} url={pdfUrl} variant="secondary">
                {this.txt("download_report")}
              </DownloadPdfButton>
            </div>
          </Grid>
          <Grid xs={12} md={3}></Grid>
          <Grid spacer spacerSize={6} />
        </Grid>
      </>
    )
  }
}

let styles

const setupStyles = () => {
  styles = stylesheet({
    heading1: {
      textAlign: "center",
      marginBottom: "0rem"
    },
    heading2: {
      marginTop: "0.5rem",
      textAlign: "center"
    },
    content: {
      display: "flex",
      justifyContent: "center",
      textAlign: "center"
    },
    centered: {
      display: "flex",
      justifyContent: "center"
    },
    button: {
      marginTop: "3rem !important",
      color: `${ReportSectionFooter.theme.colors.fontLightColor} !important`,
      background: "transparent !important",
      borderColor: `${ReportSectionFooter.theme.colors.fontLightColor} !important`,
      borderWidth: "3px !important",
      height: "3rem !important",
      paddingLeft: "2rem !important",
      paddingRight: "2rem !important"
    }
  })
}
