export interface AppConfig {
  automaticLogoutMinutes: number
  imagesRoot: string
  spinnerDelay: number
}

export const appConfig: AppConfig = {
  automaticLogoutMinutes: 30,
  imagesRoot: "/webapp/surveys_ui",
  spinnerDelay: 2000
}
