import { Team } from "app/surveys/modules/state/model/Model"
import { LogicComponentProps } from "core/components/base/LogicComponent"
import NestedList, { NestedListItem } from "lib/ui/components/nestedList/NestedList"

import { LogicComponent } from "../../../base/LogicComponent"

interface Props extends LogicComponentProps {
  onSelectTeam?: (teamId: string) => void
  teams: Team[]
  allowParentTeamSelection?: boolean
}

export default class TeamSelectionStructure extends LogicComponent<Props> {
  render() {
    const { teams, onSelectTeam, allowParentTeamSelection } = this.props

    return (
      <NestedList
        collapsable={!allowParentTeamSelection}
        selectable
        listItems={this.recursivelyCreateNestedTeams(teams)}
        onChange={onSelectTeam}
      />
    )
  }

  private recursivelyCreateNestedTeams(teams: Team[], parent?: Team): NestedListItem[] {
    return teams
      .filter(team => team.parent?.id?.toString() === parent?.id?.toString())
      .map(team => ({
        title: team.name,
        value: team.id?.toString(),
        children: this.recursivelyCreateNestedTeams(teams, team)
      }))
  }
}
