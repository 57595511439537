import { Answer, Question } from "app/surveys/modules/state/model/Model"
import { LogicComponentProps } from "core/components/base/LogicComponent"
import { LogicComponent } from "../../base/LogicComponent"
import OrderedDropdownQuestionView from "./OrderedDropdownQuestionView"

interface OrderedDropdownQuestionProps extends LogicComponentProps {
  answer?: Answer
  question: Question
  disabled?: boolean
  onOptionsSelected: (questionId: string, optionIds: string[]) => void
}

export default class OrderedDropdownQuestion extends LogicComponent<OrderedDropdownQuestionProps> {
  render() {
    super.render()

    const { answer, onOptionsSelected, question, disabled } = this.props
    const selected = answer?.options?.map(({ id }) => String(id))
    const minOptions = question.min_required_options ?? 1
    const maxOptions = question.max_required_options ?? question.options.length

    return (
      <OrderedDropdownQuestionView
        disabled={disabled}
        options={question.options}
        selected={selected}
        minOptions={minOptions}
        maxOptions={maxOptions}
        onChange={options => onOptionsSelected(question.id, options)}
      />
    )
  }
}
