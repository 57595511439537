import classNames from "classnames"
import { stylesheet } from "typestyle"

import { AppConfig } from "app/surveys_app/modules/config/appConfig"
import { ViewComponentProps } from "core/components/base/ViewComponent"
import Button from "lib/ui/components/form/Button"
import Grid from "lib/ui/components/layout/grid/Grid"
import Typography from "lib/ui/components/typography/Typography"
import { ViewComponent } from "../../../../../base/ViewComponent"
import { ReportVariant, reportBodyClass, reportLargeBodyClass } from "../../WellbeingReport"

interface Props extends ViewComponentProps {
  reportVariant: ReportVariant
}

export default class CustomAreaWellbeingConsultation extends ViewComponent<Props> {
  get componentName() {
    return ["report", "pages", "report", "CustomAreaWellbeingConsultation"]
  }

  constructor(props) {
    super(props)

    setupStyles(this.appConfig, props.reportVariant)
  }

  render() {
    const { reportVariant } = this.props

    return reportVariant === "mobile" ? this.renderMobile() : this.renderDesktopPdf()
  }

  private renderMobile() {
    return (
      <>
        <Grid sm={12} className={styles.mobileContent}>
          <div className={styles.picture}></div>
          <div className={styles.headingArea}>
            <Typography className={classNames(reportLargeBodyClass, styles.headingTop)}>
              {this.txt("heading1")}
            </Typography>
            <Typography className={classNames(reportBodyClass, styles.headingBottom)}>
              {this.txt("heading2")}
            </Typography>
          </div>
          <Button variant="secondary" className={styles.button} onClick={this.openWellbeingConsultation}>
            {this.txt("action_button")}
          </Button>
        </Grid>
      </>
    )
  }

  private renderDesktopPdf() {
    return (
      <>
        <Grid sm={6}>
          <div className={styles.picture}></div>
        </Grid>
        <Grid sm={6} className={styles.contentContainer}>
          <div className={styles.textBackground}></div>
          <div className={styles.headingArea}>
            <Typography className={classNames(reportLargeBodyClass, styles.headingTop)}>
              {this.txt("heading1")}
            </Typography>
            <Typography className={classNames(reportBodyClass, styles.headingBottom)}>
              {this.txt("heading2")}
            </Typography>
          </div>
          <Button variant="secondary" className={styles.button} onClick={this.openWellbeingConsultation}>
            {this.txt("action_button")}
          </Button>
        </Grid>
      </>
    )
  }

  private openWellbeingConsultation = () => {
    window.open(
      "https://publications.hintsa.com/hintsa-recharge-wellbeing-consultation-brochure/full-view.html",
      "_blank"
    )
  }
}

let styles

const setupStyles = (appConfig: AppConfig, reportVariant: ReportVariant) => {
  styles = stylesheet({
    headingTop: {
      marginTop: "0rem",
      marginBottom: "0.5rem",
      fontWeight: "bold"
    },

    headingBottom: {
      marginTop: "0rem"
    },
    contentContainer: {
      padding: "2rem",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      backgroundColor: CustomAreaWellbeingConsultation.theme.colors.hintsa.oatmeal.toString(),
      borderRadius: reportVariant !== "mobile" ? "0 0.8rem 0.8rem 0" : undefined
    },
    mobileContent: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: CustomAreaWellbeingConsultation.theme.colors.hintsa.oatmeal.toString(),
      paddingBottom: "2rem"
    },
    picture: {
      backgroundImage: `url("${appConfig.imagesRoot}/wellbeing_report/wellbeing_consultation.webp")`,
      backgroundSize: "cover",
      width: "100%",
      height: reportVariant === "mobile" ? "23rem" : "30rem",
      borderRadius: reportVariant !== "mobile" ? "0.8rem 0 0 0.8rem" : undefined
    },
    headingArea: {
      minHeight: reportVariant === "mobile" ? undefined : "22rem",
      minWidth: reportVariant === "mobile" ? undefined : "20rem",
      padding: reportVariant === "mobile" ? "2rem" : "2rem 1rem 0rem 3rem"
    },
    button: {
      background: "transparent !important",
      borderColor: "black !important",
      borderWidth: "3px !important",
      height: "3rem !important",
      paddingLeft: "2rem !important",
      paddingRight: "2rem !important"
    },
    textBackground: {
      position: "relative",
      top: "0",
      left: "0",
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(255, 255, 255, 0.9)",
      zIndex: -1,
      borderRadius: "0.75rem"
    }
  })
}
