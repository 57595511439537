import { PlannedSurvey, Survey, SurveyState, Team } from "app/surveys/modules/state/model/Model"
import { LogicComponentProps, LogicComponentState } from "core/components/base/LogicComponent"

import { LogicComponent } from "../../../base/LogicComponent"
import ValidateEmailPageView from "./ValidateEmailPageView"

interface Props extends LogicComponentProps {
  plannedSurvey: PlannedSurvey
  survey: Survey
  maxPage: number
  language: string
  teams?: Team[]
  email: string
  error: string
  onNext: (emailToken: string) => void
  onPrevious: () => void
  onSetLanguage: (language: string) => void
  onLogout: () => void
  onRetry: () => void
  onTokenChanged: () => void
}

interface State extends LogicComponentState {
  token: string
}

export default class ValidateEmailPage extends LogicComponent<Props, State> {
  constructor(p) {
    super(p)

    this.state = {
      token: ""
    }
  }

  render() {
    super.render()

    const { survey, maxPage, language, onSetLanguage, onLogout, teams, plannedSurvey, email, error } = this.props
    const { token } = this.state

    const surveyState = this.docDefault<SurveyState>("SurveyState")

    const teamOptions = (teams ?? []).map(team => ({
      title: team.name,
      value: team.id.toString()
    }))

    return (
      <ValidateEmailPageView
        email={email}
        survey={survey}
        maxPage={maxPage}
        language={language}
        logo={`${this.appConfig.imagesRoot}/hintsa/logo_hintsa_black.svg`}
        onNext={this.onNext}
        onPrevious={this.onPrevious}
        onSetLanguage={onSetLanguage}
        onLogout={onLogout}
        token={token}
        error={error}
        organisationSurveyTags={plannedSurvey?.organisation_survey?.organisation_survey_tags}
        onSetToken={this.onSetToken}
        onResendEmail={this.resendEmail}
      />
    )
  }

  private resendEmail = () => {
    this.setState({ token: "" })
    // post the email address back to the server, as if from the previous screen
    const { onRetry } = this.props
    onRetry()
  }

  private onSetToken = (token: string) => {
    this.setState({ token })
    const { onTokenChanged } = this.props
    onTokenChanged()
  }

  private onNext = () => {
    const { token } = this.state
    const { onNext } = this.props
    onNext(token)
  }

  private onPrevious = () => {
    // we're going back to the give-us-your-email page,
    // so clear the onscreen token
    this.setState({ token: "" })
    const { onPrevious } = this.props
    onPrevious()
  }
}
