import { ViewComponentProps, ViewComponentState } from "core/components/base/ViewComponent"
import Checkbox from "lib/ui/components/form/Checkbox"

import { QuestionOption } from "../../../../surveys/modules/state/model/Model"
import { ViewComponent } from "../../base/ViewComponent"

const noneOfTheAboveOptionId = "none_of_the_above"

interface CheckboxQuestionViewProps extends ViewComponentProps {
  options: QuestionOption[]
  selected?: string[]
  showNoneOfTheAbove?: boolean
  onChange: (optionIds: string[]) => void
  onNoneOfTheAbove: () => void
}

interface CheckboxQuestionViewState extends ViewComponentState {
  values: string[]
  noneOfTheAbove?: boolean
}

class CheckboxQuestionView extends ViewComponent<CheckboxQuestionViewProps, CheckboxQuestionViewState> {
  constructor(props) {
    super(props)

    this.state = { values: this.props.selected ?? [] }
  }

  get componentName() {
    return ["survey", "ui", "questions", "CheckboxQuestionView"]
  }

  render() {
    super.render()

    const { showNoneOfTheAbove } = this.props
    const { noneOfTheAbove } = this.state

    const checkboxes = this.props.options.map(opt => {
      const optionId = String(opt.id)
      const value = this.state.values.find(id => id === optionId)

      return (
        <Checkbox
          key={optionId}
          label={this.txt(opt.title)}
          value={value}
          fullWidth
          onChange={selected => this.onSelection(selected, optionId)}
        />
      )
    })

    if (showNoneOfTheAbove) {
      checkboxes.push(
        <Checkbox
          key={noneOfTheAboveOptionId}
          label={this.txt("noneOfTheAbove")}
          value={noneOfTheAbove}
          fullWidth
          onChange={this.onNoneOfTheAbove}
        />
      )
    }

    return <>{checkboxes}</>
  }

  private onSelection = (selected: boolean, optionId: string) => {
    this.updateState(state => {
      state.values = state.values.filter(value => value !== optionId)
      selected && state.values.push(optionId)
      state.noneOfTheAbove = state.values.length === 0
    }, this.updateParentWithSelectedOptions)
  }

  private onNoneOfTheAbove = () => {
    const { onNoneOfTheAbove } = this.props
    const { noneOfTheAbove } = this.state

    if (noneOfTheAbove) return

    onNoneOfTheAbove()

    this.updateState(state => {
      state.noneOfTheAbove = true
      state.values = []
    }, this.updateParentWithSelectedOptions)
  }

  private updateParentWithSelectedOptions = () => {
    this.props.onChange(this.state.values)
  }
}

export default CheckboxQuestionView
