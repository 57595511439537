import { Survey } from "app/surveys/modules/state/model/Model"
import { LogicComponentProps } from "core/components/base/LogicComponent"
import { TranslatedString } from "core/modules/state/model/Model"

import { LogicComponent } from "../../../base/LogicComponent"
import { surveysWithReport } from "../../base/Survey"
import StartPageView from "./StartPageView"

interface StartPageProps extends LogicComponentProps {
  error?: "notFound" | "error" | "alreadyCompleted" | "surveyClosed"
  customLogo?: string
  customWelcomeMessage?: TranslatedString
  showLanguageSelection: boolean
  showConsent: boolean
  survey?: Survey
  onSetLanguage: (language: string) => void
  onStart?: () => void
}

export default class StartPage extends LogicComponent<StartPageProps> {
  get componentName() {
    return ["survey", "pages", "start", "StartPage"]
  }

  get description() {
    const { error, survey } = this.props
    return this.txt(error ? `${error}_description` : survey.custom_welcome ?? survey.description)
  }

  get title() {
    return this.txt(this.props.error ? `${this.props.error}_title` : this.props.survey.title)
  }

  render() {
    const { survey } = this.props

    return (
      <StartPageView
        onStart={this.props.onStart}
        title={this.title}
        description={this.description}
        logo={`${this.appConfig.imagesRoot}/hintsa/logo_hintsa_black.svg`}
        customLogo={this.props.customLogo}
        customWelcomeMessage={this.props.customWelcomeMessage}
        language={this.props.language}
        onSetLanguage={this.props.onSetLanguage}
        error={this.props.error}
        showLanguageSelection={this.props.showLanguageSelection}
        showConsent={this.props.showConsent}
        numberOfQuestions={survey?.questions?.length}
        includesReport={surveysWithReport.includes(survey?.report_key?.toLowerCase())}
        estimatedTime={this.getEstimatedTime()}
        surveySubheading={this.getSurveySubheadingText()}
      />
    )
  }

  private getSurveySubheadingText() {
    const { survey } = this.props

    switch (survey?.report_key?.toLowerCase()) {
      case "hintsa":
      case "wellbeing_test":
      case "wellbeing_start":
      case "wellbeing_end":
      case "wellbeing_general":
      case "wellbeing_pulse":
        return this.txt("survey_subheading_personal_wellbeing")
      default:
        return undefined
    }
  }

  private getEstimatedTime() {
    const { survey } = this.props

    switch (survey?.report_key?.toLowerCase()) {
      case "hintsa":
      case "wellbeing_test":
      case "wellbeing_start":
      case "wellbeing_end":
      case "wellbeing_general":
        return 10
      case "wellbeing_pulse":
        return 5
      default:
        return undefined
    }
  }
}
