import { Answer, Question } from "app/surveys/modules/state/model/Model"
import { LogicComponentProps } from "core/components/base/LogicComponent"

import { LogicComponent } from "../../base/LogicComponent"
import FieldQuestionView from "./FieldQuestionView"

interface FieldQuestionProps extends LogicComponentProps {
  answer: Answer
  question: Question
  onAnswer: (questionId: string, answers: Map<string, string>) => void
}

export default class FieldQuestion extends LogicComponent<FieldQuestionProps> {
  render() {
    super.render()

    const { answer, onAnswer, question } = this.props
    const notes = new Map(answer?.options?.map(option => [option.id, option.note]))

    return (
      <FieldQuestionView notes={notes} options={question.options} onChange={notes => onAnswer(question.id, notes)} />
    )
  }
}
