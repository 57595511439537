import { LogicComponentProps } from "core/components/base/LogicComponent"
import { LogicComponent } from "../base/LogicComponent"
import RootComponentView from "./RootComponentView"

interface RootComponentProps extends LogicComponentProps {}

export default class RootComponent extends LogicComponent<RootComponentProps> {
  render(): JSX.Element {
    return <RootComponentView />
  }
}
