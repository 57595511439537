import { Answer, SurveyAnswerInstance } from "app/surveys/modules/state/model/Model"
import classNames from "classnames"
import { ViewComponentProps } from "core/components/base/ViewComponent"
import { color } from "csx"
import Grid from "lib/ui/components/layout/grid/Grid"
import Markdown from "lib/ui/components/markdown/Markdown"
import Typography from "lib/ui/components/typography/Typography"
import { CategoryPrefix, getValueColor, getValueDescription } from "lib/utils/report/WellbeingCommonReportUtils"
import {
  answersByCategory,
  getAnswerMaxPoints,
  getAnswerMinPoints,
  getAnswerPoints,
  getAnswerTitle,
  getCategoryAveragePoints,
  getScoreColorSetpointsForAnswer
} from "lib/utils/report/WellbeingPersonalReportUtils"
import React from "react"
import { stylesheet } from "typestyle"
import { ViewComponent } from "../../../../../base/ViewComponent"
import {
  ReportVariant,
  reportBodyClass,
  reportLargeBodyClass,
  reportMediumHeadingClass,
  reportSmallBodyClass,
  reportSmallHeadingClass,
  reportSubHeadingClass
} from "../../WellbeingReport"
import ScoreMeter from "../../ui/ScoreMeter"

interface Props extends ViewComponentProps {
  answer: SurveyAnswerInstance
  comparison: SurveyAnswerInstance | undefined
  categoryPrefix: string
  reportVariant: ReportVariant
}

export default class ElementDetailsContainer extends ViewComponent<Props> {
  get componentName() {
    return ["report", "pages", "report", "ElementDetailsContainer"]
  }

  get iconUrl() {
    return `${this.appConfig.imagesRoot}/cobl_categories/icon_${this.props.categoryPrefix}_w.svg`
  }

  get answers() {
    const { answer, categoryPrefix } = this.props

    return answersByCategory(answer.answers, categoryPrefix)
  }

  constructor(props) {
    super(props)

    setupStyles(props.reportVariant)
  }

  render() {
    const { reportVariant } = this.props

    if (this.answers.length === 0) return null

    return reportVariant === "pdf" ? this.renderPdf() : this.renderDesktopMobile()
  }

  private renderDesktopMobile() {
    const { categoryPrefix, reportVariant } = this.props

    return (
      <Grid container className={classNames(styles.container, styles[categoryPrefix])}>
        {this.renderCategoryAndPrimaryDescription()}
        {this.renderOptionalDescriptions()}
        {this.renderYourResults()}
        {this.renderOutcomeFocusedQuestions()}
        {this.renderBehaviorFocusedQuestions()}
      </Grid>
    )
  }

  private renderPdf() {
    const outcomeFocusedQuestions = this.renderOutcomeFocusedQuestions()
    const behaviorFocusedQuestions = this.renderBehaviorFocusedQuestions()

    return (
      <>
        <div className="hard-page-break" />
        <Grid container className={classNames(styles.container, "same-page")}>
          <Grid spacer spacerSize={4} />
          {this.renderCategoryAndPrimaryDescription()}
          {this.renderOptionalDescriptions()}
        </Grid>
        <Grid container className={classNames(styles.container, "same-page")}>
          <Grid spacer spacerSize={2} />
          {this.renderYourResults()}
          {outcomeFocusedQuestions}
          {!outcomeFocusedQuestions && behaviorFocusedQuestions}
        </Grid>
        <Grid container className={classNames(styles.container, "same-page")}>
          <Grid spacer spacerSize={2} />
          {outcomeFocusedQuestions && this.renderBehaviorFocusedQuestions()}
        </Grid>
      </>
    )
  }

  private renderCategoryAndPrimaryDescription() {
    const { answer, categoryPrefix } = this.props

    return (
      <>
        <Grid sm={12}>
          {categoryPrefix === CategoryPrefix.CORE && (
            <Typography useContrastColor className={reportSubHeadingClass}>
              {this.txt("element_specific_results")}
            </Typography>
          )}
        </Grid>
        <Grid sm={12} className={styles.categoryName}>
          <img className={styles.icon} src={this.iconUrl} />
          <Typography useContrastColor className={classNames(reportMediumHeadingClass, styles.categoryHeading)}>
            {this.txt(this.answers[0].category)}
          </Typography>
        </Grid>
        <Grid sm={12} className={styles.categoryName}>
          <Typography useContrastColor className={classNames(reportLargeBodyClass)}>
            {this.txt(`category_description_${categoryPrefix}`)}
          </Typography>
        </Grid>
      </>
    )
  }

  private renderOptionalDescriptions() {
    const { categoryPrefix, reportVariant } = this.props

    return (
      <>
        <Grid sm={8} className={styles.categoryName}>
          {[
            CategoryPrefix.CORE,
            CategoryPrefix.PHYSICAL_ACTIVITY,
            CategoryPrefix.NUTRITION,
            CategoryPrefix.GENERAL_HEALTH
          ].includes(categoryPrefix) && (
            <Typography useContrastColor component="div" className={classNames(styles.reportBody)}>
              <Markdown content={this.txt(`category_description_${categoryPrefix}_2`)} />
            </Typography>
          )}
        </Grid>
        <Grid sm={4}></Grid>
        <Grid sm={12} className={styles.hrGrid}>
          <hr className={styles.hr} />
        </Grid>
        {[
          CategoryPrefix.CORE,
          CategoryPrefix.PHYSICAL_ACTIVITY,
          CategoryPrefix.NUTRITION,
          CategoryPrefix.MENTAL_ENERGY,
          CategoryPrefix.BIOMECHANICS,
          CategoryPrefix.SLEEP_AND_RECOVERY
        ].includes(categoryPrefix) && (
          <>
            <Grid sm={6} className={styles.descriptionLeft}>
              <Typography
                useContrastColor
                component="div"
                className={classNames(reportSmallBodyClass, styles.descriptionFont)}
              >
                <Markdown content={this.txt(`category_description_${categoryPrefix}_3`)} />
              </Typography>
            </Grid>
            <Grid sm={6} className={styles.descriptionRight}>
              <Typography
                useContrastColor
                component="div"
                className={classNames(reportSmallBodyClass, styles.descriptionFont)}
              >
                <Markdown content={this.txt(`category_description_${categoryPrefix}_4`)} />
              </Typography>
            </Grid>
            <Grid sm={12} className={styles.hrGrid}>
              <hr className={styles.hr} />
            </Grid>
          </>
        )}
      </>
    )
  }

  private renderYourResults() {
    const { answer, categoryPrefix, reportVariant } = this.props
    const maxPoints = 5

    const points = getCategoryAveragePoints(answer?.answers, categoryPrefix)

    return (
      <>
        <Grid spacer spacerSize={reportVariant === "pdf" ? 0 : 2} />
        <Grid xs={12} sm={9} className={styles.yourResultsName}>
          <img className={styles.icon} src={this.iconUrl} />
          <Typography useContrastColor className={classNames(reportMediumHeadingClass, styles.categoryHeading)}>
            {this.txt("your_results")}
          </Typography>
        </Grid>
        <Grid xs={12} sm={3}>
          <div className={styles.scoreMeterContainer}>
            <div className={styles.scoreValue}>
              <Typography useContrastColor className={classNames(reportSmallHeadingClass, styles.score)}>
                {points?.toFixed(1)}
              </Typography>
              <Typography useContrastColor className={classNames(reportBodyClass, styles.scoreMax)}>
                {`/${maxPoints}`}
              </Typography>
            </div>
            <div
              style={{ backgroundColor: getValueColor(points, this.theme).toString() }}
              className={styles.scoreEvaluation}
            >
              <Typography>{getValueDescription(points, this.localization, this.appName)}</Typography>
            </div>
          </div>
        </Grid>
      </>
    )
  }

  private renderOutcomeFocusedQuestions() {
    const { categoryPrefix, reportVariant } = this.props

    let questionKeys: string[] = []

    switch (categoryPrefix) {
      case "co":
        questionKeys = ["co_alive_vital", "co_interests", "co_relationships", "co_impact", "co_purpose"]
        break
      case "gh":
        questionKeys = ["gh_healthy", "gh_missed_work", "gh_lifestyle"]
        break
      case "pa":
        questionKeys = []
        break
      case "nu":
        questionKeys = ["nu_diet_energy", "nu_digestive_comfort", "nu_body_composition"]
        break
      case "bm":
        questionKeys = ["bm_pain_daily", "bm_ergonomics", "bm_pain_back"]
        break
      case "sr":
        questionKeys = ["sr_feel_tired", "sr_sleep_quality"]
        break
      case "me":
        questionKeys = ["me_energetic", "me_work_inspires", "me_worry", "me_people_support"]
    }

    const answers = this.props.answer.answers!.filter(answer => questionKeys.includes(answer.question_key!))

    if (answers.length === 0) return null

    return (
      <>
        <Grid sm={12}>
          <Typography
            useContrastColor
            component="span"
            className={classNames(styles.focusedQuestions, reportBodyClass)}
          >
            {this.txt("outcome_focused_questions_1")}
          </Typography>
          <Typography useContrastColor component="span" className={reportBodyClass}>
            {` - ${this.txt("outcome_focused_questions_2")}`}
          </Typography>
        </Grid>
        <hr className={styles.hr} />
        {this.renderQuestionAnswers(answers)}
      </>
    )
  }

  private renderBehaviorFocusedQuestions() {
    const { categoryPrefix } = this.props

    let questionKeys: string[] = []

    switch (categoryPrefix) {
      case "co":
        questionKeys = ["co_values", "co_goals", "co_roles", "co_control"]
        break
      case "gh":
        questionKeys = ["gh_alcohol", "gh_tobacco"]
        break
      case "pa":
        questionKeys = ["pa_exercise_moderate", "pa_exercise_heavy", "pa_strength_training", "pa_daily_steps"]
        break
      case "nu":
        questionKeys = ["nu_sugars", "nu_vegetables", "nu_water"]
        break
      case "bm":
        questionKeys = ["bm_exercise_mobility", "bm_daily_sit", "bm_daily_sit_break"]
        break
      case "sr":
        questionKeys = ["sr_sleep_amount", "sr_practices", "sr_fatigue_travel", "sr_fatigue_exercise", "sr_relax"]
        break
      case "me":
        questionKeys = [
          "me_work_focus",
          "me_work_time",
          "me_pressure",
          "me_needs_freetime",
          "me_cope",
          "me_people_time"
        ]
    }

    const answers = this.props.answer.answers!.filter(answer => questionKeys.includes(answer.question_key!))

    return (
      <>
        <Grid spacer />
        <Grid sm={12}>
          <Typography
            useContrastColor
            component="span"
            className={classNames(styles.focusedQuestions, reportBodyClass)}
          >
            {this.txt("behavior_focused_questions_1")}
          </Typography>
          <Typography useContrastColor component="span" className={reportBodyClass}>
            {` - ${this.txt("behavior_focused_questions_2")}`}
          </Typography>
        </Grid>
        <hr className={styles.hr} />
        {this.renderQuestionAnswers(answers)}
      </>
    )
  }

  private renderQuestionAnswers(answers: Answer[]) {
    const { reportVariant } = this.props

    return answers
      .map(
        answer =>
          answer?.options?.[0] && (
            <React.Fragment key={answer.question_id}>
              <Grid sm={12} className={styles.hrGrid}>
                <hr className={styles.hr} />
              </Grid>
              <Grid xs={12} lg={9} className={styles.answerTitleContainer}>
                <Typography useContrastColor className={reportSmallBodyClass}>
                  {this.txt(answer.title)}
                </Typography>
              </Grid>
              <Grid xs={12} lg={3} className={styles.scoreContainer}>
                <ScoreMeter
                  className={styles.scoreMeter}
                  score={getAnswerPoints(answer)}
                  scoreDescription={getAnswerTitle(answer, this.localization)}
                  minScore={getAnswerMinPoints(answer)}
                  maxScore={getAnswerMaxPoints(answer)}
                  showBottomScoreArea
                  bottomScoreType="answerOption"
                  scoreColorCalculationMethod={"value"}
                  scoreColorSetpoints={getScoreColorSetpointsForAnswer(answer)}
                />
              </Grid>
            </React.Fragment>
          )
      )
      .filter(Boolean)
  }
}

let styles

const setupStyles = (reportVariant: ReportVariant) => {
  const ivoryColor = ElementDetailsContainer.theme.colors.hintsa.ivory
  const tangerineColor = ElementDetailsContainer.theme.colors.hintsa.tangerine
  const physicalActivityColor = color("#EA9F88")
  const generalHealthColor = ivoryColor // The give color didn't match the color in the design. Using a neutral color for now.
  const nutritionColor = color("#F8F6E3")
  const mentalEnergyColor = color("#B8CBC6")
  const biomechanicsColor = color("#7E9CA7")

  const boxShadow = "inset 0 15rem 4rem -14rem"
  const opacity = 0.8

  styles = stylesheet({
    container: {
      marginBottom: reportVariant === "pdf" ? 0 : "2rem",
      padding: reportVariant == "mobile" ? "2rem 1.5rem" : "2rem 4rem",
      borderRadius: "1rem"
    },
    icon: {
      width: "2.5rem",
      height: "5rem",
      marginRight: "1.5rem"
    },
    categoryName: {
      display: "flex"
    },
    yourResultsName: {
      marginTop: reportVariant === "pdf" ? 0 : "2rem",
      marginBottom: reportVariant === "pdf" ? 0 : undefined,
      display: "flex",
      alignItems: "center"
    },
    categoryHeading: {
      margin: 0,
      display: "flex",
      alignItems: "center",
      paddingBottom: "0.25rem"
    },
    hr: {
      backgroundColor: ElementDetailsContainer.theme.colors.hintsa.charcoal.toString(),
      opacity: 0.5,
      borderStyle: "dashed"
    },
    hrGrid: {
      width: "100%"
    },
    descriptionLeft: {
      paddingRight: "1.5rem"
    },
    descriptionRight: {
      paddingLeft: "1.5rem"
    },
    descriptionFont: {
      lineHeight: "150%"
    },
    scoreEvaluation: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: reportVariant === "mobile" ? "0.25rem" : "0 0 0.25rem 0.25rem",
      minWidth: "10rem",
      height: "2rem"
    },
    scoreMeterContainer: {
      display: reportVariant === "mobile" ? "flex" : "block",
      justifyContent: reportVariant === "mobile" ? "space-between" : undefined
    },
    scoreValue: {
      display: "flex",
      alignItems: "baseline",
      justifyContent: "center",
      marginBottom: "0.5rem"
    },
    score: {
      display: "flex",
      margin: 0
    },
    scoreMax: {
      margin: 0,
      color: ElementDetailsContainer.theme.colors.hintsa.alabaster.toString()
    },
    focusedQuestions: {
      fontWeight: "bold"
    },
    answerTitleContainer: {
      display: "flex",
      alignItems: "center"
    },
    scoreContainer: {
      display: "flex",
      alignItems: "center"
    },
    scoreMeter: {
      marginTop: "1rem"
    },
    [CategoryPrefix.CORE]: {
      boxShadow: `${boxShadow} rgba(${ivoryColor.red()}, ${ivoryColor.green()}, ${ivoryColor.blue()}, ${opacity})`
    },
    [CategoryPrefix.PHYSICAL_ACTIVITY]: {
      boxShadow: `${boxShadow} rgba(${physicalActivityColor.red()}, ${physicalActivityColor.green()}, ${physicalActivityColor.blue()}, ${opacity})`
    },
    [CategoryPrefix.GENERAL_HEALTH]: {
      boxShadow: `${boxShadow} rgba(${generalHealthColor.red()}, ${generalHealthColor.green()}, ${generalHealthColor.blue()}, ${opacity})`
    },
    [CategoryPrefix.NUTRITION]: {
      boxShadow: `${boxShadow} rgba(${nutritionColor.red()}, ${nutritionColor.green()}, ${nutritionColor.blue()}, ${opacity})`
    },
    [CategoryPrefix.MENTAL_ENERGY]: {
      boxShadow: `${boxShadow} rgba(${mentalEnergyColor.red()}, ${mentalEnergyColor.green()}, ${mentalEnergyColor.blue()}, ${opacity})`
    },
    [CategoryPrefix.BIOMECHANICS]: {
      boxShadow: `${boxShadow} rgba(${biomechanicsColor.red()}, ${biomechanicsColor.green()}, ${biomechanicsColor.blue()}, ${opacity})`
    },
    [CategoryPrefix.SLEEP_AND_RECOVERY]: {
      boxShadow: `${boxShadow} rgba(${tangerineColor.red()}, ${tangerineColor.green()}, ${tangerineColor.blue()}, ${opacity})`
    }
  })
}
