import { LogicComponentProps, LogicComponentState } from "core/components/base/LogicComponent"

import { LogicComponent } from "../../../base/LogicComponent"
import SurveyCodePageView from "./SurveyCodePageView"

interface SurveyCodePageProps extends LogicComponentProps {
  id: string
  error?: string
  language: string
  showLanguageSelection: boolean
  onLogin: (surveyCode) => void
  onSetLanguage: (language: string) => void
}

interface SurveyCodePageState extends LogicComponentState {
  surveyCode: string
  showIdInfo: boolean
}

export default class SurveyCodePage extends LogicComponent<SurveyCodePageProps, SurveyCodePageState> {
  constructor(props) {
    super(props)

    this.state = { surveyCode: "", showIdInfo: false }
  }
  render() {
    super.render()

    return (
      <SurveyCodePageView
        id={`${this.props.id}_view`}
        surveyCode={this.state.surveyCode}
        showIdInfo={this.state.showIdInfo}
        language={this.props.language}
        logo={`${this.appConfig.imagesRoot}/hintsa/logo_hintsa_black.svg`}
        error={this.props.error}
        showLanguageSelection={this.props.showLanguageSelection}
        onChangeSurveyCode={this.onChangeSurveyCode}
        onClickShowIdInfo={this.onClickShowIdInfo}
        onClickHideIdInfo={this.onClickHideIdInfo}
        onLogin={this.onLogin}
        onSetLanguage={this.props.onSetLanguage}
      />
    )
  }

  private onChangeSurveyCode = code => {
    this.updateState(state => {
      state.surveyCode = code
    })
  }

  private onClickShowIdInfo = () => {
    this.updateState(state => (state.showIdInfo = true))
  }

  private onClickHideIdInfo = () => {
    this.updateState(state => (state.showIdInfo = false))
  }

  private onLogin = () => {
    this.props.onLogin(this.state.surveyCode.trim())
  }
}
