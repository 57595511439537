import { appConfig } from "app/surveys_app/modules/config/appConfig"
import {
  LogicComponent as BaseLogicComponent,
  LogicComponentProps,
  LogicComponentState
} from "core/components/base/LogicComponent"
import { appName } from "../../AppName"

export abstract class LogicComponent<
  P extends LogicComponentProps,
  S extends LogicComponentState | void = {}
> extends BaseLogicComponent<P, S> {
  protected appConfig = appConfig

  get appName() {
    return appName
  }
}
