import classNames from "classnames"
import * as React from "react"

import { ViewComponentProps, ViewComponentState } from "core/components/base/ViewComponent"

import { stylesheet } from "typestyle"
import { ViewComponent } from "../base/ViewComponent"

const changingTabTimeout = 500

interface Props extends ViewComponentProps {
  selectedTabIndex: number
  className?: string
}

interface State extends ViewComponentState {
  currentTab: number
}

/**
 * Selects between given children
 */
export default class TransformElement extends ViewComponent<Props, State> {
  private changingTabTimeout

  constructor(props) {
    super(props)

    setupStyles()

    this.state = { currentTab: props.selectedTabIndex }
  }

  componentWillUnmount() {
    clearTimeout(this.changingTabTimeout)
  }

  render() {
    const { children, selectedTabIndex, className } = this.props
    const { currentTab } = this.state

    const childNodes = children as React.ReactNode[]

    return (
      <div className={styles.tabContainer}>
        {childNodes.map((child, i) => {
          return (
            <div
              key={i}
              className={classNames(i === selectedTabIndex ? styles.tabActive : styles.tabInactive, className)}
            >
              {child}
            </div>
          )
        })}
      </div>
    )
  }
}

let styles

const setupStyles = () => {
  styles = stylesheet({
    tabContainer: {
      position: "relative",
      width: "100%"
    },
    tabActive: {
      position: "absolute",
      width: "100%",
      top: 0,
      opacity: 1,
      zIndex: 2,
      transition: `opacity 0.5;`
    },
    tabInactive: {
      position: "absolute",
      top: 0,
      opacity: 0,
      zIndex: 1,
      transition: `opacity 0.5s;`
    }
  })
}
