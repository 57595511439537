import "core-js/stable"
import { createRoot } from "react-dom/client"
import { Provider } from "react-redux"

import { AppImpl } from "core/controller/App"
import { Store } from "core/modules/store/Store"
import posthog from "posthog-js"
import { appName } from "../../AppName"
import { setupModules } from "../../Startup"
import RootComponent from "../../components/root/RootComponent"

// Create the main application object
const app = new AppImpl()
app.name = appName

setupModules(app.moduleManager)

const store = app.moduleManager.getModule<Store>("Store").getReduxStore()

posthog.init(import.meta.env.VITE_POSTHOG_API_KEY_SURVEYS_APP, {
  api_host: import.meta.env.VITE_POSTHOG_API_HOST,
  person_profiles: "always"
})

const root = createRoot(document.getElementById("webapp_container")!)
root.render(
  <Provider store={store}>
    <RootComponent />
  </Provider>
)

// Suppress unnecessary error messages
const orgError = console.error // eslint-disable-line no-console
console.error = (...args) => {
  // eslint-disable-line no-console
  if (args && args[0].toString().indexOf("You cannot change <Router routes>;") > -1) {
    // React route changed
  } else {
    // Log the error as normally
    orgError.apply(console, args)
  }
}
