import classNames from "classnames"
import { stylesheet } from "typestyle"

import { ReportMyGoals, SurveyAnswerInstance } from "app/surveys/modules/state/model/Model"
import { ViewComponentProps, ViewComponentState } from "core/components/base/ViewComponent"
import TextField from "lib/ui/components/form/TextField"
import Grid from "lib/ui/components/layout/grid/Grid"
import Typography from "lib/ui/components/typography/Typography"
import { ViewComponent } from "../../../../../base/ViewComponent"
import {
  ReportVariant,
  reportLargeBodyClass,
  reportMediumHeadingClass,
  reportSmallBodyClass,
  reportSmallHeadingClass
} from "../../WellbeingReport"

interface Props extends ViewComponentProps {
  answer: SurveyAnswerInstance
  comparison: SurveyAnswerInstance | undefined
  reportVariant: ReportVariant
  myGoals?: ReportMyGoals
  onUpdateMyGoals: (myGoals: ReportMyGoals) => void
}

interface State extends ViewComponentState {
  myGoals: ReportMyGoals
}

const myGoalsUpdateDelay = 500

export default class ReportSectionMyPlan extends ViewComponent<Props, State> {
  get componentName() {
    return ["report", "pages", "report", "ReportSectionMyPlan"]
  }

  myGoalsUpdateTimer

  constructor(props) {
    super(props)

    setupStyles(this.appConfig, props.reportVariant)

    this.state = {
      myGoals: Object.assign(
        {
          highLevelGoal: "",
          myGoal: "",
          myActions_1: "",
          myActions_2: "",
          myActions_3: "",
          obstacles: ""
        },
        props.myGoals
      )
    }
  }

  render() {
    const { reportVariant } = this.props
    const { highLevelGoal, myGoal, myActions_1, myActions_2, myActions_3, obstacles } = this.state.myGoals

    return (
      <>
        {reportVariant === "pdf" && <div className="hard-page-break" />}
        <Grid spacer spacerSize={reportVariant === "pdf" ? -12 : 2} />
        {this.renderMyPlanBackground()}
        <Grid container className="same-page">
          <Grid spacer spacerSize={reportVariant === "mobile" ? 0 : 10} />
          <Grid xs={12} lg={6} className={styles.titleContainer}>
            <Typography useContrastColor component="span" className={reportMediumHeadingClass}>
              {this.txt("start_story_title")}
            </Typography>
          </Grid>
          <Grid xs={12} lg={6}></Grid>
          <Grid xs={12} lg={6}>
            <Typography useContrastColor className={reportLargeBodyClass}>
              {this.txt("start_story_description")}
            </Typography>
          </Grid>
          <Grid xs={12} lg={6}></Grid>
          <Grid spacer spacerSize={reportVariant === "mobile" ? 30 : 12} />
          <Grid xs={reportVariant === "mobile" ? 12 : 12} className={styles.titleContainer}>
            <div className={styles.compass} />
            <Typography
              useContrastColor
              component="span"
              className={classNames(styles.myPlan, reportMediumHeadingClass)}
            >
              {this.txt("my_plan")}
            </Typography>
          </Grid>
          {reportVariant !== "mobile" && <Grid xs={2}></Grid>}
          <Grid spacer spacerSize={reportVariant === "mobile" ? 4 : 12} />
        </Grid>
        <Grid container className={classNames("same-page", styles.container)}>
          <Grid sm={12}>
            <Typography useContrastColor className={classNames(reportSmallHeadingClass, styles.textfieldTitle)}>
              {this.txt("higher_level_goal_title")}
            </Typography>
            <Typography useContrastColor className={classNames(reportSmallBodyClass, styles.textfieldDescription)}>
              {this.txt("higher_level_goal_description")}
            </Typography>
            <TextField
              multiline
              noBorder
              rows={4}
              value={highLevelGoal}
              onChange={value => {
                this.updateState(state => (state.myGoals.highLevelGoal = value))
                this.triggerMyGoalsUpdate()
              }}
              className={styles.textField}
              placeholder={this.txt("higher_level_goal_placeholder")}
            />
          </Grid>
        </Grid>
        <Grid container className={classNames("same-page", styles.container)}>
          <Grid sm={12}>
            <Typography useContrastColor className={classNames(reportSmallHeadingClass, styles.textfieldTitle)}>
              {this.txt("my_target_title")}
            </Typography>

            <Typography useContrastColor className={classNames(reportSmallBodyClass, styles.textfieldDescription)}>
              {this.txt("my_target_description")}
            </Typography>
            <TextField
              multiline
              noBorder
              rows={4}
              className={styles.textField}
              placeholder={this.txt("my_target_placeholder")}
              value={myGoal}
              onChange={value => {
                this.updateState(state => (state.myGoals.myGoal = value))
                this.triggerMyGoalsUpdate()
              }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          className={classNames("same-page", styles.container)}
        >
          <Grid xs={12}>
            <Typography useContrastColor className={classNames(reportSmallHeadingClass, styles.textfieldTitle)}>
              {this.txt("my_actions_title")}
            </Typography>
            <Typography useContrastColor className={classNames(reportSmallBodyClass, styles.textfieldDescription)}>
              {this.txt("my_actions_description")}
            </Typography>
          </Grid>
          <Grid xs={12} lg={4} className={styles.myActionsTopMargin}>
            <TextField
              multiline
              noBorder
              rows={4}
              className={styles.textField}
              placeholder={this.txt("my_actions_placeholder_1")}
              value={myActions_1}
              onChange={value => {
                this.updateState(state => (state.myGoals.myActions_1 = value))
                this.triggerMyGoalsUpdate()
              }}
            />
          </Grid>
          <Grid xs={12} lg={4} className={styles.myActionsTopMargin}>
            <TextField
              multiline
              noBorder
              rows={4}
              className={styles.textField}
              placeholder={this.txt("my_actions_placeholder_2")}
              value={myActions_2}
              onChange={value => {
                this.updateState(state => (state.myGoals.myActions_2 = value))
                this.triggerMyGoalsUpdate()
              }}
            />
          </Grid>
          <Grid xs={12} lg={4} className={styles.myActionsTopMargin}>
            <TextField
              multiline
              noBorder
              rows={4}
              className={styles.textField}
              placeholder={this.txt("my_actions_placeholder_3")}
              value={myActions_3}
              onChange={value => {
                this.updateState(state => (state.myGoals.myActions_3 = value))
                this.triggerMyGoalsUpdate()
              }}
            />
          </Grid>
        </Grid>
        <Grid container className={classNames("same-page", styles.container)}>
          <Grid sm={12}>
            <Typography useContrastColor className={classNames(reportSmallHeadingClass, styles.textfieldTitle)}>
              {this.txt("obstacles_title")}
            </Typography>
            <Typography useContrastColor className={classNames(reportSmallBodyClass, styles.textfieldDescription)}>
              {this.txt("obstacles_description")}
            </Typography>
            <TextField
              multiline
              noBorder
              rows={4}
              className={styles.textField}
              placeholder={this.txt("obstacles_placeholder")}
              value={obstacles}
              onChange={value => {
                this.updateState(state => (state.myGoals.obstacles = value))
                this.triggerMyGoalsUpdate()
              }}
            />
          </Grid>
          <Grid spacer spacerSize={8} />
        </Grid>
      </>
    )
  }

  private updateMyGoals = () => {
    this.props.onUpdateMyGoals(this.state.myGoals)
  }

  private renderMyPlanBackground() {
    return (
      <>
        <div className={styles.backgroundContainer}>
          <div className={styles.backgroundImage} />
        </div>
      </>
    )
  }

  private triggerMyGoalsUpdate() {
    clearTimeout(this.myGoalsUpdateTimer)
    this.myGoalsUpdateTimer = setTimeout(this.updateMyGoals, myGoalsUpdateDelay)
  }
}

let styles

const setupStyles = (appConfig, reportVariant) => {
  styles = stylesheet({
    titleContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: reportVariant === "mobile" ? "center" : undefined
    },
    container: {
      paddingRight: reportVariant === "mobile" ? undefined : "1rem",
      paddingLeft: reportVariant === "mobile" ? undefined : "1rem"
    },
    compass: {
      backgroundImage: `url(${appConfig.imagesRoot}/wellbeing_report/compass.svg)`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: reportVariant === "mobile" ? "-52vw" : undefined,
      backgroundSize: reportVariant === "mobile" ? "450vw" : "cover",
      width: reportVariant === "mobile" ? "calc(100% + 3rem)" : "88rem",
      height: "80rem",
      position: "absolute",
      marginLeft: reportVariant === "mobile" ? "-2rem" : "-8rem",
      marginTop: reportVariant === "mobile" ? "4rem" : "-3rem",
      zIndex: -3
    },
    myPlan: {
      marginLeft: reportVariant === "mobile" ? undefined : "22rem"
    },
    backgroundContainer: {
      position: "relative",
      height: 0,
      marginLeft: reportVariant === "mobile" ? "-10rem" : undefined
    },
    myActionsTopMargin: {
      marginTop: reportVariant === "mobile" ? "1rem !important" : "-1rem !important"
    },
    backgroundImage: {
      position: "relative",
      marginLeft: reportVariant === "mobile" ? "inherit" : "-3rem",
      marginRight: reportVariant === "mobile" ? "inherit" : "-3rem",
      height: "80rem",
      opacity: 0.35,
      backgroundImage: `url("${appConfig.imagesRoot}/wellbeing_report/my_plan.webp")`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      boxShadow: `0 0 10rem 10rem rgb(${ReportSectionMyPlan.theme.colors.hintsa.charcoal.red()},${ReportSectionMyPlan.theme.colors.hintsa.charcoal.green()},${ReportSectionMyPlan.theme.colors.hintsa.charcoal.blue()}) inset`,
      zIndex: -5
    },
    textfieldTitle: {
      marginTop: "2rem",
      marginBottom: "0rem"
    },
    textfieldDescription: {
      marginTop: "0.5rem",
      marginBottom: "0.5rem"
    },
    textField: {
      width: "100%",
      borderRadius: "0.5rem"
    },
    placeholderText: {
      marginTop: "0.5rem",
      marginBottom: "0.5rem"
    }
  })
}
