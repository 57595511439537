import { style } from "typestyle"

import { ViewComponentProps } from "core/components/base/ViewComponent"
import Button from "lib/ui/components/form/Button"
import Form from "lib/ui/components/form/Form"
import TextField from "lib/ui/components/form/TextField"
import CardLayout from "lib/ui/components/layout/CardLayout"
import LanguageSelectorDropdown from "lib/ui/components/settings/LanguageSelectorDropdown"

import { ViewComponent } from "../../../base/ViewComponent"

interface SurveyCodePageViewProps extends ViewComponentProps {
  id: string
  surveyCode: string
  showIdInfo: boolean
  language: string
  logo: string
  error: string
  showLanguageSelection: boolean
  onLogin: () => void
  onChangeSurveyCode: (value: string) => void
  onClickShowIdInfo: () => void
  onClickHideIdInfo: () => void
  onSetLanguage: (language: string) => void
}

export default class SurveyCodePageView extends ViewComponent<SurveyCodePageViewProps, {}> {
  get componentName() {
    return ["survey", "pages", "survey_code", "SurveyCodePageView"]
  }

  private get containerClass() {
    return style({
      display: "flex",
      flexDirection: "column",
      height: "100%",
      minWidth: this.isMobile ? "18rem" : undefined,
      margin: this.isMobile ? "1.5rem" : "2.5rem"
    })
  }

  private get contentClass() {
    return style({
      display: "flex",
      flexDirection: "column"
    })
  }

  private get mobileLayoutClass() {
    return style({
      height: "100%",
      display: "flex",
      flexDirection: "column"
    })
  }
  private get welcomeClass() {
    return style({
      marginBottom: 0
    })
  }

  private get logoClass() {
    const { logo } = this.props

    return style({
      backgroundImage: `url(${logo})`,
      backgroundSize: "cover",
      width: "12.75rem",
      height: "2.25rem",
      marginBottom: "2rem"
    })
  }

  private get buttonContainerClassMobile() {
    return style({
      display: "flex",
      flexGrow: 1,
      alignItems: "flex-end",
      paddingBottom: "1rem"
    })
  }

  private get titleClass() {
    return this.isMobile
      ? style({
          marginBottom: "1em",
          fontSize: "16px",
          fontWeight: "bold"
        })
      : style({
          fontSize: "32px",
          fontWeight: "bold",
          marginTop: "0.25rem"
        })
  }

  private get textFieldClass() {
    return style({
      alignSelf: this.isMobile ? undefined : "flex-start",
      width: this.isMobile ? undefined : "20rem",
      marginBottom: "2rem"
      // marginBottom: csx.important("2rem"),
    })
  }

  private get languageSelectionClass() {
    return style({
      margin: "0 0 1rem 0"
    })
  }

  render() {
    super.render()

    return this.isMobile ? this.renderMobile() : this.renderDesktop()
  }

  private renderMobile() {
    return <div className={this.mobileLayoutClass}>{this.renderLogin()}</div>
  }

  private renderDesktop() {
    return (
      <CardLayout width="50rem" background={this.theme.background} headerBackgroundColor={this.theme.colors.primary}>
        {this.renderLogin()}
      </CardLayout>
    )
  }

  private renderLanguageSelection() {
    const { language, showLanguageSelection, onSetLanguage } = this.props

    if (!showLanguageSelection) return null

    return (
      <div className={this.languageSelectionClass}>
        <LanguageSelectorDropdown language={language} setLanguage={onSetLanguage} />
      </div>
    )
  }

  private renderLogin() {
    return (
      <div className={this.containerClass}>
        {this.renderLogo()}
        <div className={this.welcomeClass}>
          <h3 className={this.titleClass}>{this.txt("welcome")}</h3>
        </div>
        {this.renderLanguageSelection()}
        <Form className={this.contentClass} onSubmit={this.onSubmit} autocomplete="off">
          <TextField
            id="enter_your_id"
            label={this.txt("enter_your_id")}
            className={this.textFieldClass}
            name={"login"}
            required={true}
            value={this.props.surveyCode}
            onChange={this.props.onChangeSurveyCode}
          />
          {this.renderError()}
          {this.renderLoginButtons()}
        </Form>
      </div>
    )
  }

  private renderLogo() {
    return <div className={this.logoClass}></div>
  }

  private renderError() {
    if (!this.props.error) return undefined

    const errorClass = style({
      minHeight: "2em",
      textAlign: "center",
      paddingTop: "0.25em",
      color: this.theme.colors.errorColor.toString(),
      backgroundColor: this.theme.colors.errorColor.fade(0.2).toString()
    })

    return <div className={errorClass}>{this.props.error}</div>
  }

  private renderLoginButtons() {
    return (
      <div className={this.isMobile ? this.buttonContainerClassMobile : undefined}>
        <Button type="submit" fullWidth={this.isMobile} disabled={!this.props.surveyCode}>
          {this.txt("core", "components", "ui", "form", "login")}
        </Button>
      </div>
    )
  }

  private onSubmit = event => {
    event.preventDefault()
    this.props.onLogin()
    return false
  }
}
