import * as EmailValidator from "email-validator"

import { OrganisationSurveyTag, PlannedSurvey, Survey, Team } from "app/surveys/modules/state/model/Model"
import TeamSelectionDialog from "app/surveys_app/components/ui/dialogs/select_team/TeamSelectionDialog"
import { LogicComponentProps, LogicComponentState } from "core/components/base/LogicComponent"

import { LogicComponent } from "../../../base/LogicComponent"
import PersonalDetailsPageView from "./PersonalDetailsPageView"

export interface PersonalDetailsResults {
  firstName: string
  lastName: string
  weight: number
  height: number
  email: string
  country: string
  selectedTeam: string
  birthYear: Date | null
  selectedTags: string[]
}

interface Props extends LogicComponentProps {
  plannedSurvey: PlannedSurvey
  survey: Survey
  maxPage: number
  language: string
  onNext: (results: PersonalDetailsResults) => void
  onSetLanguage: (language: string) => void
  onLogout: () => void
}

interface State extends LogicComponentState {
  firstName: string
  lastName: string
  weight: string
  height: string
  email: string
  country: string
  selectedTeam: string
  birthYear: Date | null
  showNestedTeamsDialog?: boolean
  tagSelection: { [category: string]: string }
  emailErrorDescription?: string
}

export default class PersonalDetailsPage extends LogicComponent<Props, State> {
  get componentName() {
    return ["survey", "pages", "personal_details", "PersonalDetailsPage"]
  }

  constructor(p) {
    super(p)

    this.state = {
      firstName: "",
      lastName: "",
      weight: "",
      height: "",
      email: "",
      country: "",
      birthYear: null,
      selectedTeam: "",
      tagSelection: {}
    }
  }

  private validEmailDomains: string[] = []
  private enabledTagCategories: string[] = []
  private organisationSurveyTags: string[] = []

  render() {
    super.render()

    const { survey, maxPage, language, onSetLanguage, onLogout, plannedSurvey } = this.props
    const { firstName, lastName, selectedTeam, email, emailErrorDescription } = this.state

    this.validEmailDomains =
      plannedSurvey.organisation_survey?.allowed_domains
        ?.split(",")
        .map(domain => domain.toLowerCase().trim())
        .filter(Boolean) ?? []

    // Filter out empty categories, can't use enabled_tag_categories directly
    this.enabledTagCategories = [
      ...new Set(plannedSurvey?.organisation_survey?.enabled_tags?.map(category => category.category_name ?? ""))
    ]

    this.organisationSurveyTags = plannedSurvey.organisation_survey?.organisation_survey_tags ?? []

    return (
      <>
        {this.renderDialogs()}
        <PersonalDetailsPageView
          maxQuestions={survey.questions.length}
          survey={survey}
          maxPage={maxPage}
          language={language}
          logo={`${this.appConfig.imagesRoot}/hintsa/logo_hintsa_black.svg`}
          onNext={this.onNext}
          onSelectTeam={this.onSelectTeam}
          onSetLanguage={onSetLanguage}
          onLogout={onLogout}
          selectedTeam={selectedTeam}
          teams={plannedSurvey.organisation_survey.teams as Team[]}
          enabledTags={plannedSurvey.organisation_survey?.enabled_tags ?? []}
          enabledTagCategories={this.enabledTagCategories}
          tagSelection={this.state.tagSelection}
          email={email}
          validEmailDomains={this.validEmailDomains}
          nextEnabled={this.validateValues()}
          emailError={!!email && !this.validateEmail()}
          emailErrorDescription={emailErrorDescription}
          organisationSurveyTags={this.organisationSurveyTags}
          firstName={firstName}
          lastName={lastName}
          onSetEmail={this.onSetEmail}
          onSetFirstName={this.onSetFirstName}
          onSetLastName={this.onSetLastName}
          onShowNestedTeams={this.onShowNestedTeams}
          onSetTagSelection={this.onSetTagSelection}
        />
      </>
    )
  }

  private renderDialogs() {
    const { showNestedTeamsDialog } = this.state
    const { plannedSurvey } = this.props

    if (showNestedTeamsDialog) {
      return (
        <TeamSelectionDialog
          teams={plannedSurvey.organisation_survey.teams as Team[]}
          allowParentTeamSelection={plannedSurvey.organisation_survey?.organisation_survey_tags?.includes(
            OrganisationSurveyTag.AllowSelectingParentTeam
          )}
          onSelectTeam={(selectedTeam: string) =>
            this.setState({ showNestedTeamsDialog: false, selectedTeam: selectedTeam?.toString() })
          }
          onClose={() => this.setState({ showNestedTeamsDialog: false })}
        />
      )
    }
  }

  private validateValues() {
    const { plannedSurvey } = this.props

    const { firstName, lastName, selectedTeam, tagSelection } = this.state

    const organisationSurveyTags = plannedSurvey.organisation_survey?.organisation_survey_tags

    if (organisationSurveyTags.includes(OrganisationSurveyTag.AskUserName)) {
      if (!firstName.trim() || !lastName.toString()) return false
    }

    if (organisationSurveyTags.includes(OrganisationSurveyTag.AskUserEmail) && !this.validateEmail()) return false

    if (
      organisationSurveyTags.includes(OrganisationSurveyTag.SelectTeam) &&
      !!plannedSurvey.organisation_survey?.teams?.length &&
      !selectedTeam
    )
      return false

    if (
      organisationSurveyTags.includes(OrganisationSurveyTag.SelectTags) &&
      this.enabledTagCategories.length > 0 &&
      this.enabledTagCategories.map(category => tagSelection[category]).filter(Boolean).length <
        this.enabledTagCategories.length
    ) {
      return false
    }

    return true
  }

  private getCleanEmail() {
    return this.state.email?.trim().toLowerCase() ?? ""
  }

  private validateEmail() {
    const email = this.getCleanEmail()
    return EmailValidator.validate(email)
  }

  private validateEmailDomain() {
    const domain = this.getCleanEmail().split("@")[1].toLocaleLowerCase()
    return !this.validEmailDomains || this.validEmailDomains?.length === 0 || this.validEmailDomains.includes(domain)
  }

  private onSelectTeam = (selectedTeam: string) => {
    this.setState({ selectedTeam })
  }

  private onSetEmail = (email: string) => {
    this.setState({ email, emailErrorDescription: undefined })
  }

  private onSetFirstName = (firstName: string) => {
    this.setState({ firstName })
  }

  private onSetLastName = (lastName: string) => {
    this.setState({ lastName })
  }

  private onNext = () => {
    const { firstName, lastName, weight, height, email, country, selectedTeam, birthYear, tagSelection } = this.state
    const { onNext, plannedSurvey } = this.props

    const selectedTags = (plannedSurvey?.organisation_survey?.enabled_tag_categories ?? []).map(
      category => tagSelection[category.category_name]
    )

    // only attempt to validate the email address if it is provided
    if (this.state.email) {
      if (!this.validateEmailDomain()) {
        this.setState({ emailErrorDescription: this.txt("email_domain_error", [this.validEmailDomains.join(", ")]) })
        return
      }
    }

    this.setState({ emailErrorDescription: undefined })

    onNext({
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      weight: parseInt(weight.trim()),
      height: parseInt(height.trim()),
      email: email.trim(),
      country: country.trim(),
      selectedTeam: selectedTeam.trim(),
      selectedTags,
      birthYear
    })
  }

  private onShowNestedTeams = () => {
    this.setState({ showNestedTeamsDialog: true })
  }

  private onSetTagSelection = (tagId: string) => {
    const { plannedSurvey } = this.props

    const tags = plannedSurvey.organisation_survey.enabled_tags
    const category = tags.find(t => t.id == tagId)?.category_name
    if (!category) return

    this.updateState(state => {
      state.tagSelection[category] = tagId
    })
  }
}
