import { AppConfig } from "app/surveys_app/modules/config/appConfig"
import classNames from "classnames"
import clipboardCopy from "clipboard-copy"
import { ViewComponentProps } from "core/components/base/ViewComponent"
import * as csx from "csx"
import Button from "lib/ui/components/form/Button"
import Grid from "lib/ui/components/layout/grid/Grid"
import Typography from "lib/ui/components/typography/Typography"
import { stylesheet } from "typestyle"
import { ViewComponent } from "../../../../../base/ViewComponent"
import { ReportVariant, reportBodyClass, reportLargeBodyClass } from "../../WellbeingReport"

interface Props extends ViewComponentProps {
  reportVariant: ReportVariant
}

const surveyLink = "https://www.hintsa.com/assessment/"

export default class CustomAreaInviteFriends extends ViewComponent<Props> {
  get componentName() {
    return ["report", "pages", "report", "CustomAreaInviteFriends"]
  }

  constructor(props) {
    super(props)

    setupStyles(this.appConfig)
  }

  render() {
    return (
      <>
        <Grid sm={12} className={styles.container}>
          <img className={styles.coblImage} src={`${this.appConfig.imagesRoot}/hintsa/logo_hintsa_plain.svg`}></img>
          <div className={styles.headingArea}>
            <Typography useContrastColor className={classNames(reportLargeBodyClass, styles.headingTop)}>
              {this.txt("heading1")}
            </Typography>
            <Typography useContrastColor className={classNames(reportBodyClass, styles.headingBottom)}>
              {this.txt("content")}
            </Typography>
          </div>
          <Typography useContrastColor className={classNames(reportBodyClass, styles.link)}>
            {surveyLink}
          </Typography>
          <Button variant="secondary" className={styles.button} onClick={this.copyLink}>
            {this.txt("action_button")}
          </Button>
        </Grid>
      </>
    )
  }

  private copyLink = () => {
    clipboardCopy(surveyLink)
  }
}

let styles

const setupStyles = (appConfig: AppConfig) => {
  styles = stylesheet({
    coblImage: {
      width: "13rem",
      height: "13rem"
    },
    link: {
      backgroundColor: CustomAreaInviteFriends.theme.colors.hintsa.granite.toString(),
      padding: "0.25rem 1rem",
      borderRadius: "0.5rem"
    },
    headingTop: {
      textAlign: "center",
      marginBottom: "0.5rem",
      fontWeight: "bold"
    },
    headingBottom: {
      marginTop: "0rem",
      textAlign: "center",
      marginBottom: "0.5rem"
    },
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      padding: "2rem"
    },
    button: {
      color: csx.important(CustomAreaInviteFriends.theme.colors.fontLightColor.toString()),
      background: csx.important("transparent"),
      borderColor: csx.important(CustomAreaInviteFriends.theme.colors.fontLightColor.toString()),
      borderWidth: csx.important("3px"),
      height: csx.important("3rem"),
      paddingLeft: csx.important("2rem"),
      paddingRight: csx.important("2rem")
    }
  })
}
