import { ViewComponentProps } from "core/components/base/ViewComponent"
import { style } from "typestyle"

import Markdown from "lib/ui/components/markdown/Markdown"
import MarkdownConverter from "lib/ui/components/markdown/MarkdownConverter"
import Typography from "lib/ui/components/typography/Typography"
import { ViewComponent } from "../../base/ViewComponent"

interface PrivacyBannerProps extends ViewComponentProps {}

export default class PrivacyBanner extends ViewComponent<PrivacyBannerProps> {
  get componentName() {
    return ["survey", "banners", "PrivacyBanner"]
  }

  private get containerClass() {
    return style({
      display: "flex",
      width: "100%",
      height: "4rem",
      backgroundColor: "black",
      position: this.isMobile ? undefined : "fixed",
      alignItems: "center",
      justifyContent: "center",
      paddingLeft: "2rem",
      paddingRight: "2rem",
      zIndex: 10
    })
  }

  private get glyphClass() {
    return style({
      color: PrivacyBanner.theme.colors.primaryContrast.toString(),
      marginRight: "0.25rem"
    })
  }

  render() {
    super.render()

    const {} = this.props

    return (
      <div className={this.containerClass}>
        <Typography useContrastColor>
          <MarkdownConverter linksInNewWindow>
            <Markdown content={this.txt("privacy_note")} />
          </MarkdownConverter>
        </Typography>
      </div>
    )
  }
}
