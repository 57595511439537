import classNames from "classnames"
import * as ReactDOM from "react-dom"
import { style } from "typestyle"

import { ViewComponentProps, ViewComponentState } from "core/components/base/ViewComponent"
import Spinner from "lib/ui/components/symbols/Spinner"

import { ViewComponent } from "../base/ViewComponent"

const progressTimeoutMs = 500
const hidingBlockDivTimeoutMs = 500
const showingBlockDivTimoutMs = 250

interface Props extends ViewComponentProps {
  showBlockDiv: boolean
  className?: boolean
}

interface State extends ViewComponentState {
  // Enabled after showBlockDiv property is true and a timeout is done
  blockDiv?: boolean
  progress?: boolean
  // There's a delay needed to allow the opacity animation to complete
  hidingBlockDiv?: boolean
}

export default class FadedBlockDiv extends ViewComponent<Props, State> {
  private progressTimeout
  private showingBlockDivTimeout
  private hidingBlockDivTimeout

  private ignoreTransition: boolean = false

  get componentName(): string[] {
    return ["ui", "BlockDiv"]
  }

  private get blockDivClass() {
    return style({
      position: "fixed",
      width: "100vw",
      height: "100vh",
      zIndex: 9998,
      top: 0,
      left: 0
    })
  }

  private get backgroundColorClass() {
    const { blockDiv } = this.state

    return style({
      backgroundColor: `rgba(0,0,0,${blockDiv ? 0.3 : 0})`,
      transition: this.ignoreTransition ? undefined : `background-color 0.5s ease;`
    })
  }

  private get spinnerClass(): string {
    return style({
      position: "fixed",
      width: "100vw",
      height: "100vh",
      zIndex: 9999,
      top: 0,
      left: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    })
  }

  constructor(props) {
    super(props)
    // Block was shown initially, show spinner after a timeout
    if (props.showBlockDiv) {
      this.ignoreTransition = true
      setTimeout(() => {
        this.ignoreTransition = false
      }, 100)
      this.progressTimeout = setTimeout(() => {
        this.setState({ progress: true })
      }, progressTimeoutMs)
    }
    this.state = { blockDiv: props.showBlockDiv }
  }

  componentWillUnmount() {
    clearTimeout(this.progressTimeout)
    clearTimeout(this.hidingBlockDivTimeout)
  }

  render() {
    super.render()
    if (this.props.showBlockDiv && !this.state.blockDiv) {
      this.showingBlockDivTimeout = setTimeout(() => {
        this.setState({ blockDiv: true })
        this.progressTimeout = setTimeout(() => {
          this.setState({ progress: true })
        }, progressTimeoutMs)
      }, showingBlockDivTimoutMs)
    }

    if (!this.props.showBlockDiv) {
      this.notShowingBlockDiv()
    }

    return ReactDOM.createPortal(this.renderBlockDiv(), document.getElementById("webapp_container")!)
  }

  notShowingBlockDiv() {
    clearTimeout(this.showingBlockDivTimeout)

    if (this.state.blockDiv && !this.state.hidingBlockDiv) {
      setTimeout(() => {
        this.setState({ hidingBlockDiv: true, blockDiv: false })
      }, 0)

      clearTimeout(this.progressTimeout)
      this.progressTimeout = setTimeout(() => {
        this.setState({ hidingBlockDiv: false })
      }, hidingBlockDivTimeoutMs)
    }
  }

  private renderBlockDiv() {
    const { blockDiv, hidingBlockDiv } = this.state

    if (!blockDiv && !hidingBlockDiv) return false

    return (
      <div>
        <div className={classNames(this.backgroundColorClass, this.blockDivClass, this.props.className)}>
          {this.props.children}
        </div>
        {this.renderSpinner()}
      </div>
    )
  }

  private renderSpinner() {
    if (!this.props.showBlockDiv || !this.state.progress) return undefined
    return (
      <div className={this.spinnerClass}>
        <Spinner />
      </div>
    )
  }
}
